import { InboxOutlined } from '@ant-design/icons';
import { Alert, Button, Empty, Form, Radio } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import clsx from 'clsx';
import { SetStateAction } from 'react';
import FormItem from 'src/components/form-item/FormItem';
import { IconError } from 'src/components/icons/error/IconError';
import Loader from 'src/components/loader/Loader';
import Select from 'src/components/select/Select';
import AutoUploadFile from 'src/components/table/file/with-upload/AutoUploadFile';
import {
  NO_CUSTOMERS_ADDED,
  NO_RESULTS,
  NO_SUPPLIERS_ADDED,
  ONE_FILE,
  REQUIRED,
} from 'src/constants/validation';
import useInvoiceUploadForm from 'src/hooks/use-invoice-upload-form';
import { TFileWithAbort } from 'src/hooks/use-upload-invoice';
import {
  getCustomerSelectOptions,
  getSupplierSelectOptions,
} from 'src/utils/option-renderer';
import styles from './InvoiceUploadForm.module.scss';

type TInvoiceUploadFormProps = {
  loading: boolean;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
  setUploadedIds: React.Dispatch<SetStateAction<string[]>>;
  setFiles: React.Dispatch<React.SetStateAction<TFileWithAbort[]>>;
  files: TFileWithAbort[];
  uploadedIds: string[];
  onClose: () => void;
};

const InvoiceUploadForm = ({
  loading,
  onClose,
  closeModal,
  setUploadedIds,
  setFiles,
  files,
  uploadedIds,
  setLoading,
}: TInvoiceUploadFormProps) => {
  const {
    isFilePicked,
    optionsLoading,
    pickerDisabled,
    deleteLoading,
    isCustomer,
    isFilesLimit,
    primaryDisabled,
    extraShown,
    form,
    options,
    setSearch,
    setLoadingList,
    setFailedList,
    setExtraShown,
    setDeleteLoading,
    setShowAlert,
    alertShown,
    debouncedSearch,
    customers,
    suppliers,
    company,
    submit,
  } = useInvoiceUploadForm(onClose, uploadedIds, files, setLoading);

  const emptyDataMessage = isCustomer ? NO_CUSTOMERS_ADDED : NO_SUPPLIERS_ADDED;

  const normFile = () => {
    return [];
  };

  return (
    <>
      <Form
        className={styles.form}
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          assignee: 'customer',
        }}
      >
        <div className={styles.container}>
          <div className={styles.row}>
            <FormItem name="assignee" className={styles.segment}>
              <Radio.Group
                disabled={isFilePicked}
                options={[
                  {
                    value: 'customer',
                    label: 'Customer',
                  },
                  {
                    value: 'supplier',
                    label: 'Supplier',
                  },
                ]}
                optionType="button"
                buttonStyle="solid"
              />
            </FormItem>
            <FormItem
              className={styles.select}
              name="company"
              rules={[
                {
                  required: true,
                  message: REQUIRED,
                },
              ]}
            >
              <Select
                showSearch
                disabled={isFilePicked}
                filterOption={false}
                loading={optionsLoading}
                options={options}
                placeholder={`Select ${isCustomer ? 'customer' : 'supplier'}`}
                onSearch={(search) => setSearch(search)}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      debouncedSearch ? NO_RESULTS : emptyDataMessage
                    }
                  />
                }
                optionRender={(option) => {
                  if (isCustomer) {
                    return getCustomerSelectOptions(customers, option.value);
                  }
                  return getSupplierSelectOptions(suppliers, option.value);
                }}
              />
            </FormItem>
          </div>
          <FormItem
            name="invoices"
            label="Invoice files"
            valuePropName="fileList"
            className={styles.filePicker}
            rules={[
              {
                validator: () => {
                  if (files.length || pickerDisabled) {
                    setExtraShown(true);
                    return Promise.resolve();
                  }
                  setExtraShown(false);
                  return Promise.reject();
                },
                message: ONE_FILE,
              },
            ]}
            getValueFromEvent={normFile}
            extra={
              extraShown
                ? 'Up to 20 PDF files allowed. Maximum size 3 MB per file'
                : undefined
            }
          >
            <Dragger
              className={styles.dragger}
              accept="application/pdf"
              multiple
              maxCount={20}
              disabled={pickerDisabled}
              beforeUpload={(file) => {
                setFiles((files) => {
                  if (files.length < 20) {
                    files.push({ file, abort: false, error: false });
                  }

                  return [...files];
                });
                return false;
              }}
              fileList={[]}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined
                  className={clsx({ [styles.icon]: pickerDisabled })}
                />
              </p>
              <p className="ant-upload-text">
                {isFilesLimit
                  ? 'Maximum number of files reached'
                  : 'Click or drag files to this area to upload'}
              </p>
              {!isFilesLimit && (
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              )}
            </Dragger>
          </FormItem>
          {alertShown && (
            <Alert
              className={styles.alert}
              closable
              message="All files failed. At least 1 file must be uploaded."
              type="error"
              showIcon
              onClose={() => setShowAlert(false)}
              icon={<IconError height="16" width="16" />}
            />
          )}
          <div className={styles.filesContainer}>
            {files
              .map((file, i) => ({
                error: file.error,
                children: (
                  <AutoUploadFile
                    key={file.file.uid}
                    file={file.file}
                    abortTrigger={file.abort}
                    disabled={deleteLoading}
                    setDisabled={setDeleteLoading}
                    supplierId={isCustomer ? null : company}
                    customerId={isCustomer ? company : null}
                    onUpload={(value) => {
                      setUploadedIds((ids) => {
                        ids.push(value);
                        return [...ids];
                      });
                      setFiles((files) => {
                        const file = files[i];
                        if (file) {
                          file.uploadedId = value;
                        }
                        return [...files];
                      });
                    }}
                    onFailed={(value) => {
                      setFailedList((list) => {
                        list[i] = value;
                        return [...list];
                      });
                      setFiles((files) => {
                        const file = files[i];
                        if (file) {
                          files[i].error = value;
                        }
                        return [...files];
                      });
                    }}
                    onLoading={(value) => {
                      setLoadingList((list) => {
                        list[i] = value;
                        return [...list];
                      });
                    }}
                    onDelete={() => {
                      setFiles((files) => {
                        files.splice(i, 1);
                        return [...files];
                      });
                      setUploadedIds((ids) => {
                        const res = ids.slice();
                        const id = file.uploadedId;
                        const index = uploadedIds.findIndex((el) => el === id);
                        const indexFound = !!(index + 1);

                        if (id && indexFound) {
                          res.splice(index, 1);
                        }
                        return [...res];
                      });
                      setLoadingList((list) => {
                        list.splice(i, 1);
                        return [...list];
                      });
                      setFailedList((list) => {
                        list.splice(i, 1);
                        return [...list];
                      });
                    }}
                  />
                ),
              }))
              .sort((a, b) => Number(b.error) - Number(a.error))
              .map((el) => el.children)}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button disabled={deleteLoading} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={primaryDisabled || loading}
            type="primary"
            onClick={submit}
          >
            OK
          </Button>
        </div>
      </Form>
      <Loader visible={loading} />
    </>
  );
};

export default InvoiceUploadForm;
