import { useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { useModalContext } from 'src/contexts/modal-context';
import { useUserContext } from 'src/contexts/user-context';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedInvoicesListRecord,
  getInvoiceColumnSetting,
  getMappedInvoiceData,
} from 'src/utils/map-invoices-table';
import useSources from './use-sources';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';
import { InvoicesColumns } from 'src/constants/invoices';

const POLL_INTERVAL = 30000; // Note: 30sec

let abortController: AbortController | null = null;

const useSouecesList = () => {
  const {
    userContextState: { isAdmin },
  } = useUserContext();

  const noActions = !isAdmin;

  const { getInvoices } = useSources();

  const { modalContextDispatch } = useModalContext();

  const [open, setOpen] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [resultId, setResultId] = useState<string | null>(null);
  const [resultName, setResultName] = useState<string | null>(null);
  const [resultFiltersAllowed, setResultFiltersAllowed] = useState(false);
  const [data, setData] = useState<TMappedInvoicesListRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const fetchInvoices = async (
    tableParams: ITableParamsWithSearch,
    skip = false
  ) => {
    if (!skip) {
      setLoading(true);
    } else {
      abortController = new AbortController();
    }
    const result = await getInvoices(tableParams, abortController);
    if (result.result?.data.data) {
      setData(getMappedInvoiceData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: InvoicesColumns.CREATED_AT,
      sortOrder: SortOrder.DESC,
      fetcher: fetchInvoices,
    });

  useEffect(() => {
    const interval = setInterval(() => {
      fetchInvoices(tableParams, true);
    }, POLL_INTERVAL);
    return () => {
      clearInterval(interval);
      if (abortController) {
        abortController.abort();
        abortController = null;
      }
    };
  }, [tableParams]);

  const openParsedResult = (id: string, name: string, allowed: boolean) => {
    setResultId(id);
    setResultName(name);
    setResultVisible(true);
    setResultFiltersAllowed(allowed);
  };

  const closeParsedResult = () => {
    setResultId(null);
    setResultName(null);
    setResultVisible(false);
    setResultFiltersAllowed(false);
  };

  const columns = useMemo(() => {
    const res = getInvoiceColumnSetting({
      modalDispatch: modalContextDispatch,
      isActionAllowed: isAdmin && !!data.length,
      search: tableParams.search,
      open: openParsedResult,
    });
    return res;
  }, [tableParams, isAdmin, data.length]);

  const tableWidth = useTableWidth(columns);
  return {
    columns,
    data,
    total,
    handleTableChange,
    setSearch,
    loading,
    width: tableWidth,
    debouncedSearch: tableParams.search,
    current: tableParams.pagination?.current,
    search,
    open,
    setOpen,
    noActions,
    resultId,
    resultVisible,
    resultName,
    resultFiltersAllowed,
    closeParsedResult,
  };
};

export default useSouecesList;
