import { Typography, theme } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Logo } from 'src/components/logo/Logo';
import styles from './SignUpValidationModal.module.scss';
import Url from 'src/components/table/url/Url';

type TSignUpValidationModalProps = {
  className?: string;
  name: string | null;
  email: string | null;
};

const SignUpValidationModal = ({
  className,
  name,
  email,
}: TSignUpValidationModalProps) => {
  const {
    token: { colorBgContainer, boxShadowTertiary, borderRadiusLG, paddingXL },
  } = theme.useToken();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.transitionIn]: mounted,
        },
        className
      )}
      style={{
        backgroundColor: colorBgContainer,
        boxShadow: boxShadowTertiary,
        borderRadius: borderRadiusLG,
        padding: paddingXL,
      }}
    >
      <Logo width="188" height="47" />
      <Typography.Title className={styles.title} level={5}>
        Invite link is no longer active
      </Typography.Title>
      <Typography.Text className={styles.description}>
        Please contact {name}
        {' ('}
        {
          <Url
            className={styles.url}
            value={`mailto:${email}`}
            iconShown={false}
          >
            {email}
          </Url>
        }
        {')'}, who invited you, and request another link.
      </Typography.Text>
    </div>
  );
};

export default SignUpValidationModal;
