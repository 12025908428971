import { Form, GetProp, UploadFile, UploadProps } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Mode } from 'src/components/products/drawer/ProductsDrawer';
import File from 'src/components/table/file/File';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { useMessageContext } from 'src/contexts/message-context';
import { IEpd, IProduct } from 'src/types/general';
import { getErrorText } from 'src/utils/get-error-text';
import useProducts from './use-products';
import { FileStatus } from 'src/constants/invoices';
import useFiles from './use-files';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const useProductCrudForm = (
  productId: string | null,
  mode: Mode | null,
  onClose: () => void,
  open: boolean
) => {
  const { showErrorMessage, showSuccessMessage } = useMessageContext();

  const {
    loading: measurementsLoading,
    uoms,
    currencies,
  } = useMeasurementsContext();

  const { getProduct, createProduct, updateProduct, uploadFile, fetchFile } =
    useProducts();

  const { deleteEpd } = useFiles();

  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [fetchingError, setFetchingError] = useState('');
  const [file, setFile] = useState<UploadFile | null>(null);
  const initialValue = useRef<IProduct>();

  const [form] = Form.useForm<IProduct>();

  const name = Form.useWatch('name', form);
  const materialNumber = Form.useWatch('materialNumber', form);
  const partNumber = Form.useWatch('partNumber', form);
  const barCode = Form.useWatch('barCode', form);
  const externalEpdUrl = Form.useWatch('externalEpdUrl', form);
  const [epdFile, setEpdFile] = useState<IEpd | null>(null);
  const [epdFileToDelete, setEpdFileToDelete] = useState<IEpd | null>(null);
  const createdAt = Form.useWatch('createdAt', form);
  const createdByName = Form.useWatch('createdByName', form);
  const modifiedAt = Form.useWatch('modifiedAt', form);
  const modifiedByName = Form.useWatch('modifiedByName', form);
  const unitOfMeasurementId = Form.useWatch('unitOfMeasurementId', form);
  const rrpPrice = Form.useWatch('rrpPrice', form);
  const quantity = Form.useWatch('quantity', form);
  const currency = Form.useWatch('currency', form);

  const isChanged = useMemo(() => {
    if (
      !initialValue.current &&
      (name || materialNumber || partNumber || barCode || externalEpdUrl)
    ) {
      return true;
    }
    if (initialValue.current?.name !== name) {
      return true;
    }
    if (initialValue.current?.materialNumber !== materialNumber) {
      return true;
    }
    if (initialValue.current?.partNumber !== partNumber) {
      return true;
    }
    if (initialValue.current?.barCode !== barCode) {
      return true;
    }
    if (initialValue.current?.externalEpdUrl !== externalEpdUrl) {
      return true;
    }
    if (
      initialValue.current?.currency !== currency &&
      !!initialValue.current?.currency
    ) {
      return true;
    }
    if (
      initialValue.current?.unitOfMeasurementId !== unitOfMeasurementId &&
      !!initialValue.current?.unitOfMeasurementId
    ) {
      return true;
    }
    if (initialValue.current?.quantity !== quantity && quantity !== null) {
      return true;
    }
    if (initialValue.current?.rrpPrice !== rrpPrice && rrpPrice !== null) {
      return true;
    }
    if (file) {
      if (epdFile) {
        return epdFile.fileName !== file.name;
      }
      return true;
    }
    if (epdFile?.hiveStatus === FileStatus.UPLOADED) {
      return true;
    }
    if (epdFileToDelete) {
      return true;
    }
    return false;
  }, [
    name,
    materialNumber,
    partNumber,
    barCode,
    externalEpdUrl,
    file,
    epdFile,
    rrpPrice,
    quantity,
    unitOfMeasurementId,
    currency,
    epdFileToDelete,
  ]);

  const resetForm = () => {
    const emptyValue = undefined;
    form.resetFields(emptyValue);
    initialValue.current = emptyValue;
    setData(null);
    setFile(null);
    setLoading(false);
    setFileError(false);
    setEpdFile(null);
  };

  useEffect(() => {
    const beforeUnloadHandler = (event: Event) => {
      event.preventDefault();

      event.returnValue = true;
    };
    if (isChanged) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [isChanged]);

  useEffect(() => {
    if (fetchingError) {
      setFetchingError('');
    }
  }, [externalEpdUrl]);

  const deleteEpdFile = async () => {
    setLoading(true);
    if (!epdFileToDelete?.epdId) {
      return;
    }

    const res = await deleteEpd(epdFileToDelete.epdId);

    if (!res.errors) {
      setEpdFileToDelete(null);
    }
  };

  const submit = () => {
    if (fetchingError) {
      return;
    }
    form
      .validateFields()
      .then(async () => {
        await deleteEpdFile();
        const body = {
          name,
          materialNumber,
          partNumber,
          barCode,
          externalEpdUrl,
          rrpPrice: rrpPrice || 0,
          quantity: quantity || 0,
          currency: rrpPrice ? currency || 0 : 0,
          unitOfMeasurementId: quantity ? unitOfMeasurementId : null,
        };
        if (mode === Mode.CREATE) {
          setLoadingText('Adding');
          try {
            const res = await createProduct(body);
            if (!res.errors) {
              showSuccessMessage(`Product "${name}" added`);
            } else {
              const text = getErrorText(res.errors.response?.data.errors);
              if (text.length) {
                text.forEach((err) => {
                  showErrorMessage(err);
                });
              } else {
                showErrorMessage(`Product "${name}" not added`);
              }
            }
          } catch (err) {}
          closeDrawer();
          setLoading(false);
          return;
        }
        if (mode === Mode.EDIT && productId) {
          setLoadingText('Saving');
          if (file) {
            const res = await handleUpload();
            if (res.errors) {
              setFileError(true);
              setLoading(false);
              return;
            }
          }
          try {
            const res = await updateProduct({
              id: productId,
              ...body,
            });
            if (!res.errors) {
              showSuccessMessage('Changes saved');
            } else {
              const text = getErrorText(res.errors.response?.data.errors);
              if (text.length) {
                text.forEach((err) => {
                  showErrorMessage(err);
                });
              } else {
                showErrorMessage('Changes not saved');
              }
            }
          } catch (err) {}

          closeDrawer();
          return;
        }
      })
      .catch(() => {
        console.log('Validation failed');
      });
  };

  const handleUpload = async () => {
    if (!productId || !file) {
      throw 'Not all data has been provided';
    }
    const formData = new FormData();

    formData.append('File', file as FileType);
    formData.append('ProductId', productId);
    setLoading(true);
    return await uploadFile(formData);
  };

  const reuploadFile = async () => {
    await handleUpload();
    setLoading(false);
  };

  const fetchEpdLink = async () => {
    if (!externalEpdUrl || !productId) {
      return;
    }
    setFetchingError('');
    setLoading(true);
    const result = await fetchFile(externalEpdUrl, productId);
    if (result.result?.data) {
      setEpdFile(result.result.data);
      setFile(null);
    }
    if (result.errors?.response?.data) {
      setFetchingError(getErrorText(result.errors.response.data.errors)[0]);
    }
    setLoading(false);
  };

  const [data, setData] = useState<IProduct | null>(null);

  useEffect(() => {
    const fetchDetails = async (id: string) => {
      setLoadingText('Loading');
      setLoading(true);
      const { result } = await getProduct(id);
      if (result?.data) {
        const product = result.data;
        initialValue.current = product;
        setData(product);
      }
      setLoading(false);
    };
    if (productId) {
      fetchDetails(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (data) {
      form.setFieldsValue({
        ...data,
        quantity: data.quantity || null,
        rrpPrice: data.rrpPrice || null,
        unitOfMeasurementId:
          data.unitOfMeasurementId ||
          (uoms.find((uom) => uom.label === 'pcs')?.value as string),
        currency:
          data.currency ||
          (currencies.find((curr) => curr.label === 'AUD')?.value as number),
      });
      setEpdFile(data.epdFile);
    } else {
      form.setFieldValue(
        'unitOfMeasurementId',
        uoms.find((uom) => uom.label === 'pcs')?.value
      );
      form.setFieldValue(
        'currency',
        currencies.find((curr) => curr.label === 'AUD')?.value
      );
    }
  }, [data, currencies, uoms, open]);

  const draggerProps: UploadProps = {
    style: {
      ...(file
        ? {
            display: 'none',
          }
        : {
            maxHeight: '146px',
          }),
    },
    accept: 'application/pdf',
    multiple: false,
    maxCount: 1,
    onRemove: () => {
      setFile(null);
      setFileError(false);
    },
    itemRender: (_, file, __, actions) => {
      return (
        <File
          file={{
            fileName: file.name || '',
            blobName: epdFile?.blobName || file.name || '',
            createdAt: new Date(),
          }}
          onLocalDelete={actions.remove}
          isError={fileError}
          retry={reuploadFile}
        />
      );
    },
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: file ? [file] : [],
  };

  const closeDrawer = () => {
    const broadcast = new BroadcastChannel(BROADCAST_NAME);
    resetForm();
    onClose();
    broadcast.postMessage('refresh');
    broadcast.close();
  };

  return {
    form,
    name,
    materialNumber,
    partNumber,
    barCode,
    externalEpdUrl,
    epdFile,
    createdAt,
    createdByName,
    modifiedAt,
    modifiedByName,
    draggerProps,
    isChanged,
    loading: loading || measurementsLoading,
    submit,
    closeDrawer,
    loadingText,
    setEpdFile,
    uoms,
    currencies,
    unitOfMeasurementId,
    currency,
    quantity,
    rrpPrice,
    fetchEpdLink,
    deleteEpdFile: () => {
      setEpdFileToDelete(epdFile);
      setEpdFile(null);
    },
    fetchingError,
  };
};

export default useProductCrudForm;
