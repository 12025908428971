export enum InviteFormFields {
  COMPANY_NAME = 'companyName',
  EMAIL = 'email',
  EMAIL_BODY = 'emailBody',
  SUBJECT = 'subject',
  TITLE = 'title',
}

export const inviteFormLabels = {
  [InviteFormFields.COMPANY_NAME]: 'Company',
  [InviteFormFields.EMAIL]: 'Email',
  [InviteFormFields.SUBJECT]: 'Subject',
  [InviteFormFields.TITLE]: 'Title',
  [InviteFormFields.EMAIL_BODY]: 'Body',
};
