import { Typography } from 'antd';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { StaticReportColumns } from 'src/constants/sustainability-report';
import styles from 'src/styles/actions.module.scss';
import { ITableParams } from 'src/types/generic';
import useDebouncer from './use-debouncer';
import useReports from './use-reports';

const useProjectsFilters = (supplierTableParams: ITableParams) => {
  const { getProjectIds, getProjectNames } = useReports();

  const [filtersById, setFiltersById] = useState<ColumnFilterItem[]>([]);
  const [filtersByName, setFiltersByName] = useState<ColumnFilterItem[]>([]);
  const [filterIdSearch, setFilterIdSearch] = useState('');
  const [filtersIdLoading, setFiltersIdLoading] = useState<boolean>(false);
  const [debouncedFilterIdSearch, setDebouncedFilterIdSearch] = useState('');
  const [filterNameSearch, setFilterNameSearch] = useState('');
  const [filtersNameLoading, setFiltersNameLoading] = useState<boolean>(false);
  const [debouncedFilterNameSearch, setDebouncedFilterNameSearch] =
    useState('');

  useDebouncer(setDebouncedFilterIdSearch, filterIdSearch);
  useDebouncer(setDebouncedFilterNameSearch, filterNameSearch);

  useEffect(() => {
    const fetchProjectById = async () => {
      if (!!debouncedFilterIdSearch && debouncedFilterIdSearch.length < 3) {
        return;
      }
      setFiltersIdLoading(true);
      const res = await getProjectIds({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        sortField: 'personalId',
        sortOrder: SortOrder.ASC,
        search: debouncedFilterIdSearch,
      });

      if (res.result?.data) {
        const filters = res.result.data.data.map((project) => ({
          text: (
            <div className={styles.filter}>
              <Typography.Text className={styles.filterTitle}>
                {project || '–'}
              </Typography.Text>
            </div>
          ),
          value: project,
        }));

        setFiltersById(filters);
      }
      setFiltersIdLoading(false);
    };
    fetchProjectById();
  }, [debouncedFilterIdSearch]);

  useEffect(() => {
    const fetchProjectByName = async () => {
      if (!!debouncedFilterNameSearch && debouncedFilterNameSearch.length < 3) {
        return;
      }
      setFiltersNameLoading(true);
      const res = await getProjectNames({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        sortField: 'name',
        sortOrder: SortOrder.ASC,
        search: debouncedFilterNameSearch,
      });

      if (res.result?.data) {
        const filters = res.result.data.data.map((project) => ({
          text: (
            <div className={styles.filter}>
              <Typography.Text className={styles.filterTitle}>
                {project || '–'}
              </Typography.Text>
            </div>
          ),
          value: project,
        }));

        setFiltersByName(filters);
      }
      setFiltersNameLoading(false);
    };
    fetchProjectByName();
  }, [debouncedFilterNameSearch]);

  const sortedFiltersById = useMemo(() => {
    const selectedValues =
      supplierTableParams.filters?.[StaticReportColumns.PROJECT_ID];

    const res = filtersById.slice();

    if (!selectedValues) {
      return res;
    }
    const sortedFilters = res.sort((a, b) => {
      const aValue = a.value as React.Key;
      const bValue = b.value as React.Key;

      const isASelected = selectedValues.includes(aValue);
      const isBSelected = selectedValues.includes(bValue);
      return Number(isBSelected) - Number(isASelected);
    });

    return sortedFilters;
  }, [supplierTableParams.filters, filtersById]);

  const sortedFiltersByName = useMemo(() => {
    const selectedValues =
      supplierTableParams.filters?.[StaticReportColumns.PROJECT_NAME];

    const res = filtersByName.slice();

    if (!selectedValues) {
      return res;
    }
    const sortedFilters = res.sort((a, b) => {
      const aValue = a.value as React.Key;
      const bValue = b.value as React.Key;

      const isASelected = selectedValues.includes(aValue);
      const isBSelected = selectedValues.includes(bValue);
      return Number(isBSelected) - Number(isASelected);
    });

    return sortedFilters;
  }, [supplierTableParams.filters, filtersByName]);

  return {
    sortedFiltersById,
    sortedFiltersByName,
    setFilterIdSearch,
    setFilterNameSearch,
    filtersIdLoading,
    filtersNameLoading,
    debouncedFilterIdSearch,
    debouncedFilterNameSearch,
  };
};

export default useProjectsFilters;
