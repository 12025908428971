import useUploadInvoice from 'src/hooks/use-upload-invoice';
import ModalWrapper from '../../modal/wrapper/ModalWrapper';
import InvoiceUploadForm from './form/InvoiceUploadForm';

type TInvoiceUploadModalProps = {
  onClose: () => void;
  visible: boolean;
};

const InvoiceUploadModal = ({ onClose, visible }: TInvoiceUploadModalProps) => {
  const {
    loading,
    setUploadedIds,
    closeModal,
    uploadedIds,
    files,
    setFiles,
    cleanUp,
    setLoading,
  } = useUploadInvoice(onClose);

  return (
    <ModalWrapper
      visible={visible}
      width={520}
      maxHeight={520}
      title="Upload invoice for/from"
      close={closeModal}
    >
      <InvoiceUploadForm
        loading={loading}
        setLoading={setLoading}
        closeModal={closeModal}
        onClose={() => {
          cleanUp();
          onClose();
        }}
        uploadedIds={uploadedIds}
        setUploadedIds={setUploadedIds}
        files={files}
        setFiles={setFiles}
      />
    </ModalWrapper>
  );
};

export default InvoiceUploadModal;
