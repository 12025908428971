import { Button } from 'antd';
import ModalWrapper from '../../modal/wrapper/ModalWrapper';
import styles from './InvoiceParsingResult.module.scss';
import InvoiceParsingResultTable from './table/InvoiceParsingResultTable';

type TInvoiceParsingResultProps = {
  onClose: () => void;
  visible: boolean;
  id: string | null;
  name: string | null;
  isFiltersAllowed: boolean;
};

const InvoiceParsingResult = ({
  onClose,
  visible,
  id,
  name,
  isFiltersAllowed,
}: TInvoiceParsingResultProps) => {
  return (
    <ModalWrapper
      visible={visible}
      width={948}
      height={640}
      title={`Processing result: ${name || '-'} `}
      close={onClose}
    >
      <div className={styles.container}>
        <InvoiceParsingResultTable
          id={id}
          isFiltersAllowed={isFiltersAllowed}
        />

        <div className={styles.buttonContainer}>
          <Button type="primary" onClick={onClose}>
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default InvoiceParsingResult;
