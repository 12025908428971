import dayjs from 'dayjs';
import { EXPORT_FAILED } from 'src/constants/validation';
import {
  HTTP,
  TResponse,
  TValidationError,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TAssignProductsParams,
  TEditAssignedProductParams,
  TExportProductOffersParams,
  TGetCompaniesParams,
  TGetCompaniesResponse,
  TGetCustomerProductsParams,
  TGetCustomerProductsResponse,
  TGetCustomerResponse,
  TGetCustomersParams,
  TGetCustomersResponse,
  TGetSupplierProductPurchaseHistoryParams,
  TGetSupplierProductPurchaseHistoryResponse,
  TGetSupplierProductResponse,
  TGetSupplierProductsParams,
  TGetSupplierProductsResponse,
  TGetSupplierResponse,
  TGetSuppliersParams,
  TGetSuppliersResponse,
  TInviteCompanyParams,
} from 'src/types/backend';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from 'src/utils/date-formatter';
import { downloadFile } from 'src/utils/download';
import { getParams, getParamsWithSearch } from 'src/utils/get-params';

export type TGetSuppliers = (
  data: TGetSuppliersParams,
  url?: string
) => Promise<TResponse<TGetSuppliersResponse, unknown>>;
export type TGetCompanies = (
  data: TGetCompaniesParams
) => Promise<TResponse<TGetCompaniesResponse, unknown>>;
export type TAddSupplier = (
  name: string
) => Promise<TResponse<unknown, unknown>>;
export type TDecoupleSupplier = (
  connectionId: string
) => Promise<TResponse<unknown, unknown>>;
export type TGetCustomers = (
  data: TGetCustomersParams
) => Promise<TResponse<TGetCustomersResponse, unknown>>;
export type TGetCustomer = (
  id: string
) => Promise<TResponse<TGetCustomerResponse, unknown>>;
export type TGetCustomerProducts = (
  id: string,
  data: TGetCustomerProductsParams
) => Promise<TResponse<TGetCustomerProductsResponse, unknown>>;
export type TAssignProducts = (
  id: string,
  data: TAssignProductsParams
) => Promise<TResponse<unknown, TValidationError>>;
export type TEditAssignedProduct = (
  customerId: string,
  offerId: string,
  data: TEditAssignedProductParams
) => Promise<TResponse<unknown, unknown>>;
export type TDeleteAssignedProduct = (
  customerId: string,
  offerId: string
) => Promise<TResponse<unknown, unknown>>;
export type TGetSupplier = (
  id: string
) => Promise<TResponse<TGetSupplierResponse, unknown>>;
export type TGetSupplierProducts = (
  id: string,
  data: TGetSupplierProductsParams
) => Promise<TResponse<TGetSupplierProductsResponse, unknown>>;
export type TGetSupplierProduct = (
  supplierId: string,
  productOfferId: string
) => Promise<TResponse<TGetSupplierProductResponse, unknown>>;
export type TGetSupplierProductPurchaseHistory = (
  supplierId: string,
  productOfferId: string,
  data: TGetSupplierProductPurchaseHistoryParams
) => Promise<TResponse<TGetSupplierProductPurchaseHistoryResponse, unknown>>;
export type TInviteCompany = (
  data: TInviteCompanyParams,
  isCustomer: boolean
) => Promise<
  TResponse<TGetSupplierProductPurchaseHistoryResponse, TValidationError>
>;

const useCompanies = () => {
  const { fetch } = useNetworkContext();

  const getSuppliers: TGetSuppliers = async (data, url = '/supplier/paged') => {
    const params = getParamsWithSearch(data);

    const result = await fetch<TGetSuppliersResponse, unknown>({
      url,
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getCustomers: TGetCustomers = async (data) => {
    const params = getParamsWithSearch(data);
    params.append('IncludeDeleted', `${data.includeDeleted}`);

    const result = await fetch<TGetCustomersResponse, unknown>({
      url: '/customer/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getCompanies: TGetCompanies = async (data) => {
    const params = getParamsWithSearch(data);

    const result = await fetch<TGetCompaniesResponse, unknown>({
      url: '/company/all/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getCustomer: TGetCustomer = async (id) => {
    const result = await fetch<TGetCustomerResponse, unknown>({
      url: `/customer/${id}`,
      method: HTTP.GET,
    });

    return result;
  };

  const getCustomerProducts: TGetCustomerProducts = async (id, data) => {
    const params = getParams(data);

    const result = await fetch<TGetCustomerProductsResponse, unknown>({
      url: `/customer/${id}/productOffers/paged`,
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const assignProducts: TAssignProducts = async (id, data) => {
    const result = await fetch<unknown, TValidationError>({
      url: `/customer/${id}/productOffers`,
      method: HTTP.POST,
      data,
    });

    return result;
  };

  const editAssignedProduct: TEditAssignedProduct = async (
    customerId,
    offerId,
    data
  ) => {
    const result = await fetch({
      url: `/customer/${customerId}/productOffer/${offerId}`,
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  const deleteAssignedProduct: TDeleteAssignedProduct = async (
    customerId,
    offerId
  ) => {
    const result = await fetch({
      url: `/customer/${customerId}/productOffer/${offerId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const addSupplier: TAddSupplier = async (name) => {
    const result = await fetch({
      url: '/supplier',
      method: HTTP.POST,
      data: {
        name,
      },
    });

    return result;
  };

  const decoupleSupplier: TDecoupleSupplier = async (connectionId) => {
    const result = await fetch({
      url: `/supplier/${connectionId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const getSupplier: TGetSupplier = async (id) => {
    const result = await fetch<TGetSupplierResponse, unknown>({
      url: `/supplier/${id}`,
      method: HTTP.GET,
    });

    return result;
  };

  const getSupplierProducts: TGetSupplierProducts = async (id, data) => {
    const params = getParams(data);

    const result = await fetch<TGetSupplierProductsResponse, unknown>({
      url: `/supplier/${id}/productOffers/paged`,
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getSupplierProduct: TGetSupplierProduct = async (
    supplierId,
    productOfferId
  ) => {
    const result = await fetch<TGetSupplierProductResponse, unknown>({
      url: `/supplier/${supplierId}/productOffer/${productOfferId}`,
      method: HTTP.GET,
    });

    return result;
  };

  const getSupplierProductPurchaseHistory: TGetSupplierProductPurchaseHistory =
    async (supplierId, productOfferId, data) => {
      const params = getParams(data);

      const result = await fetch<
        TGetSupplierProductPurchaseHistoryResponse,
        unknown
      >({
        url: `/supplier/${supplierId}/productOffer/${productOfferId}/purchasedItems/paged`,
        method: HTTP.GET,
        params,
      });

      return result;
    };

  const inviteCompany: TInviteCompany = async (data, isCustomer) => {
    const result = await fetch<
      TGetSupplierProductPurchaseHistoryResponse,
      TValidationError
    >({
      url: `/${isCustomer ? 'customer' : 'supplier'}/invite`,
      method: HTTP.POST,
      data,
    });
    return result;
  };

  const exportProductOffers = async (
    data: TExportProductOffersParams,
    id: string,
    isSupplier = true
  ) => {
    const params = getParams(data);
    fetch<Blob, unknown>({
      url: `/${isSupplier ? 'supplier' : 'customer'}/${id}/productOffers/export`,
      responseType: 'blob',
      method: HTTP.POST,
      params,
    })
      .then((response) => {
        const company = response.result?.headers['company-name'];

        const date = dayjs();
        downloadFile(
          response,
          `AssignedProducts_${isSupplier ? 'by' : 'to'}_${company}_${date.format(DEFAULT_DATE_FORMAT)}_${date.format(DEFAULT_TIME_FORMAT)}`
        );
      })
      .catch(() => {
        showErrorMessage(EXPORT_FAILED);
      });
  };

  return {
    getSuppliers,
    getCompanies,
    addSupplier,
    decoupleSupplier,
    getCustomers,
    getCustomer,
    getCustomerProducts,
    assignProducts,
    editAssignedProduct,
    deleteAssignedProduct,
    getSupplier,
    getSupplierProducts,
    getSupplierProduct,
    getSupplierProductPurchaseHistory,
    inviteCompany,
    exportProductOffers,
  };
};

export default useCompanies;
function showErrorMessage(EXPORT_FAILED: any) {
  throw new Error('Function not implemented.');
}
