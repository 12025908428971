import { HTTP, useNetworkContext } from 'src/contexts/network-context';
import { TGetEmailPreviewParams } from 'src/types/backend';

const usePreview = () => {
  const { fetch } = useNetworkContext();

  const getEmailPreview = async (data: TGetEmailPreviewParams) => {
    const result = await fetch<string, unknown>({
      url: '/preview/company/invitation',
      method: HTTP.POST,
      data,
    });

    return result;
  };

  return {
    getEmailPreview,
  };
};

export default usePreview;
