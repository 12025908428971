export enum SignUpFormFields {
  COMPANY = 'company',
  INDUSTRY = 'industry',
  COUNTRY = 'country',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
}

export const signInFormLabels: Partial<Record<SignUpFormFields, string>> = {
  [SignUpFormFields.COMPANY]: 'Your company',
  [SignUpFormFields.INDUSTRY]: 'Industry',
  [SignUpFormFields.COUNTRY]: 'Country',
  [SignUpFormFields.FIRST_NAME]: 'First name',
  [SignUpFormFields.LAST_NAME]: 'Last name',
  [SignUpFormFields.EMAIL]: 'Email',
  [SignUpFormFields.PASSWORD]: 'Password',
  [SignUpFormFields.PASSWORD_CONFIRM]: 'Confirm password',
};
