import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import {
  FileStatus,
  InvoicesColumns,
  defaultInvoicesColumns,
  getInvoicesColumnRender,
  invoicesColumnFixed,
  invoicesColumnLabel,
  invoicesColumnSortOrder,
  invoicesColumnWidth,
} from 'src/constants/invoices';
import { TModalContextStateAction } from 'src/contexts/modal-context';
import { IInvoiceRecord } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { IMappedInvoice } from 'src/types/mappings';

export type TMappedInvoicesListRecord = IMappedInvoice & {
  key: string;
};

const getViewPermission = (status: FileStatus) => {
  switch (status) {
    case FileStatus.FAILED:
    case FileStatus.PROCESSED:
    case FileStatus.PARTIALLY_PROCESSED:
      return true;
    default:
      return false;
  }
};

export const getMappedInvoiceData = (
  data: IInvoiceRecord[]
): TMappedInvoicesListRecord[] => {
  return data.map((row) => {
    const res: TMappedInvoicesListRecord = {
      key: row.id,
      [InvoicesColumns.ORDER]: row.purchaseOrderNumber,
      [InvoicesColumns.INVOICE]: {
        fileName: row.fileName,
        blobName: row.blobName,
        createdAt: row.createdAt,
      },
      [InvoicesColumns.STATUS]: row.hiveStatus,
      [InvoicesColumns.CUSTOMER]: row.customer?.name,
      [InvoicesColumns.SUPPLIER]: row.supplier?.name,
      [InvoicesColumns.CREATED_AT]: row.createdAt,
      [InvoicesColumns.CREATED_BY]: row.createdByFullName,
      [InvoicesColumns.CREATED_BY_EMAIL]: row.createdByEmail,
      [InvoicesColumns.ACTIONS]: {
        canDelete: row.canDelete,
        canViewResult: getViewPermission(row.hiveStatus),
      },
    };
    return res;
  });
};

type TGetInvoiceColumnSettingParams = {
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  isActionAllowed: boolean;
  search: string;
  open: (id: string, name: string, failed: boolean) => void;
};

export const getInvoiceColumnSetting = ({
  modalDispatch,
  isActionAllowed,
  search,
  open,
}: TGetInvoiceColumnSettingParams) => {
  const render = getInvoicesColumnRender({
    search,
    modalDispatch,
    open,
  });
  const result: ColumnsType<TMappedInvoicesListRecord> =
    defaultInvoicesColumns.map(
      (property): ArrayElement<ColumnsType<TMappedInvoicesListRecord>> => {
        switch (property) {
          case InvoicesColumns.ORDER:
          case InvoicesColumns.INVOICE:
          case InvoicesColumns.STATUS:
          case InvoicesColumns.CUSTOMER:
          case InvoicesColumns.SUPPLIER:
          case InvoicesColumns.CREATED_AT:
          case InvoicesColumns.CREATED_BY:
          case InvoicesColumns.CREATED_BY_EMAIL:
            return {
              title: invoicesColumnLabel[property],
              width: invoicesColumnWidth[property],
              key: property,
              fixed: invoicesColumnFixed[property],
              ellipsis: true,
              defaultSortOrder: invoicesColumnSortOrder[property],
              dataIndex: property,
              sorter: true,
              showSorterTooltip: false,
              sortIcon: (props) => (
                <IconSort
                  width="16"
                  height="16"
                  order={props.sortOrder as SortOrder}
                />
              ),
              render: render[property],
            };
          case InvoicesColumns.ACTIONS:
            if (!isActionAllowed) {
              return { hidden: true };
            }
            return {
              width: invoicesColumnWidth[property],
              key: property,
              fixed: invoicesColumnFixed[property],
              dataIndex: property,
              render: render[property],
            };

          default:
            return { hidden: true };
        }
      }
    );
  return result;
};
