import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconChipExtraction.module.scss';
import { TIconProps } from 'src/types/icons';

type IconChipExtractionProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement>;

export const IconChipExtraction: FunctionComponent<IconChipExtractionProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M5 12C5 13.7667 5.575 15.3084 6.725 16.625C7.875 17.9417 9.325 18.7167 11.075 18.95C11.3417 18.9834 11.5625 19.1 11.7375 19.3C11.9125 19.5 12 19.7334 12 20C12 20.2833 11.8792 20.5167 11.6375 20.7C11.3958 20.8834 11.125 20.9583 10.825 20.925C8.575 20.6417 6.70833 19.6542 5.225 17.9625C3.74167 16.2709 3 14.2834 3 12C3 9.73335 3.7375 7.75418 5.2125 6.06252C6.6875 4.37085 8.55 3.37502 10.8 3.07502C11.1167 3.04168 11.3958 3.11252 11.6375 3.28752C11.8792 3.46252 12 3.70002 12 4.00002C12 4.26668 11.9125 4.50002 11.7375 4.70002C11.5625 4.90002 11.3417 5.01668 11.075 5.05002C9.325 5.28335 7.875 6.05835 6.725 7.37502C5.575 8.69168 5 10.2334 5 12ZM17.175 13H10C9.71667 13 9.47917 12.9042 9.2875 12.7125C9.09583 12.5209 9 12.2834 9 12C9 11.7167 9.09583 11.4792 9.2875 11.2875C9.47917 11.0958 9.71667 11 10 11H17.175L15.3 9.12502C15.1 8.92502 15 8.68752 15 8.41252C15 8.13752 15.1 7.90002 15.3 7.70002C15.5 7.50002 15.7333 7.40002 16 7.40002C16.2667 7.40002 16.5 7.50002 16.7 7.70002L20.3 11.3C20.5 11.5 20.6 11.7334 20.6 12C20.6 12.2667 20.5 12.5 20.3 12.7L16.7 16.3C16.5 16.5 16.2667 16.5958 16 16.5875C15.7333 16.5792 15.5 16.475 15.3 16.275C15.1 16.075 15 15.8417 15 15.575C15 15.3084 15.1 15.075 15.3 14.875L17.175 13Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
