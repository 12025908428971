import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconBreakingNews.module.scss';
import { TIconProps } from 'src/types/icons';

type IconBreakingNewsProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement>;

export const IconBreakingNews: FunctionComponent<IconBreakingNewsProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M7 17H10C10.2833 17 10.5208 16.9042 10.7125 16.7125C10.9042 16.5208 11 16.2833 11 16C11 15.7167 10.9042 15.4792 10.7125 15.2875C10.5208 15.0958 10.2833 15 10 15H7C6.71667 15 6.47917 15.0958 6.2875 15.2875C6.09583 15.4792 6 15.7167 6 16C6 16.2833 6.09583 16.5208 6.2875 16.7125C6.47917 16.9042 6.71667 17 7 17ZM7 13H10C10.2833 13 10.5208 12.9042 10.7125 12.7125C10.9042 12.5208 11 12.2833 11 12C11 11.7167 10.9042 11.4792 10.7125 11.2875C10.5208 11.0958 10.2833 11 10 11H7C6.71667 11 6.47917 11.0958 6.2875 11.2875C6.09583 11.4792 6 11.7167 6 12C6 12.2833 6.09583 12.5208 6.2875 12.7125C6.47917 12.9042 6.71667 13 7 13ZM17 7C16.7167 7 16.4792 7.09583 16.2875 7.2875C16.0958 7.47917 16 7.71667 16 8V12C16 12.2833 16.0958 12.5208 16.2875 12.7125C16.4792 12.9042 16.7167 13 17 13C17.2833 13 17.5208 12.9042 17.7125 12.7125C17.9042 12.5208 18 12.2833 18 12V8C18 7.71667 17.9042 7.47917 17.7125 7.2875C17.5208 7.09583 17.2833 7 17 7ZM7 9H10C10.2833 9 10.5208 8.90417 10.7125 8.7125C10.9042 8.52083 11 8.28333 11 8C11 7.71667 10.9042 7.47917 10.7125 7.2875C10.5208 7.09583 10.2833 7 10 7H7C6.71667 7 6.47917 7.09583 6.2875 7.2875C6.09583 7.47917 6 7.71667 6 8C6 8.28333 6.09583 8.52083 6.2875 8.7125C6.47917 8.90417 6.71667 9 7 9ZM4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4ZM4 19H20V5H4V19ZM17 17C17.2833 17 17.5208 16.9042 17.7125 16.7125C17.9042 16.5208 18 16.2833 18 16C18 15.7167 17.9042 15.4792 17.7125 15.2875C17.5208 15.0958 17.2833 15 17 15C16.7167 15 16.4792 15.0958 16.2875 15.2875C16.0958 15.4792 16 15.7167 16 16C16 16.2833 16.0958 16.5208 16.2875 16.7125C16.4792 16.9042 16.7167 17 17 17Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
