import { theme } from 'antd';
import { PropsWithChildren } from 'react';
import { SignInIllustration } from 'src/components/sign-in/illustration/SignInIllustration';
import styles from './Layout.module.scss';

export type TGeneralLayoutProps = PropsWithChildren;

const InitialLayout = ({ children }: TGeneralLayoutProps) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <div className={styles.container} style={{ backgroundColor: colorPrimary }}>
      <SignInIllustration className={styles.illustration} />
      {children}
    </div>
  );
};

export default InitialLayout;
