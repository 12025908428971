import { Button } from 'antd';
import { NO_RESULTS } from 'src/constants/validation';
import useCompaniesList from 'src/hooks/use-companies-list';
import { TMappedCompanyRecord } from 'src/utils/map-companies-table';
import ModalWrapper from '../modal/wrapper/ModalWrapper';
import Table from '../table/Table';
import TableHeader from '../table/header/TableHeader';
import styles from './AddSupplierModal.module.scss';

type TAddSupplierModalProps = {
  onClose: () => void;
  visible: boolean;
};

const AddSupplierModal = ({ onClose, visible }: TAddSupplierModalProps) => {
  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    debouncedSearch,
    setSelectedId,
    selectedId,
    creating,
    createSupplier,
    search,
    close,
  } = useCompaniesList(onClose);

  return (
    <ModalWrapper
      visible={visible}
      width={1128}
      height={568}
      title="Add hive supplier"
      close={close}
    >
      <TableHeader
        search={search}
        searchPlaceholder={'GLN, supplier name, address'}
        setSearch={setSearch}
        title="Select a row to add supplier"
        bold={false}
        noActions
      />
      <Table<TMappedCompanyRecord>
        emptyTextDescription={
          debouncedSearch.length > 2
            ? NO_RESULTS
            : 'Start searching to see suppliers'
        }
        rowClassName={(row) => {
          if (row.isConnected) {
            return styles.disabled;
          }
          if (row.isSelected) {
            return styles.selected;
          }
          return styles.interactive;
        }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        onRow={(row, id) => ({
          onClick: () => {
            if (row.isConnected) {
              return;
            }
            if (id === selectedId || typeof id === 'undefined') {
              setSelectedId(null);
            } else {
              setSelectedId(id);
            }
          },
        })}
        loading={loading}
        width={width}
        height={365}
        isPagination={false}
      />
      <div className={styles.footer}>
        <Button disabled={creating} onClick={close}>
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={selectedId === null}
          loading={creating}
          onClick={async () => {
            await createSupplier();
            close();
          }}
        >
          Add
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default AddSupplierModal;
