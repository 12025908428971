import { Button, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { IconAttachment } from 'src/components/icons/attachment/IconAttachment';
import { IconDelete } from 'src/components/icons/delete/IconDelete';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconRestore } from 'src/components/icons/restore/IconRestore';
import { IconSync } from 'src/components/icons/sync/IconSync';
import useFiles from 'src/hooks/use-files';
import { IEpd } from 'src/types/general';
import { formatDate } from 'src/utils/date-formatter';
import Highlighter from '../highlighter/Highlighter';
import styles from './File.module.scss';

const { Text } = Typography;

type TFileProps = {
  file?: Partial<IEpd> | null;
  readOnly?: boolean;
  invoice?: boolean;
  reupload?: () => void;
  onLocalDelete?: () => void;
  retry?: () => void;
  isError?: boolean;
  disabled?: boolean;
  search?: string;
  errorMessage?: string;
  onDelete?: () => void;
  isThirdActionVisible?: boolean;
  isSoft?: boolean;
};

const File = ({
  readOnly = false,
  invoice = false,
  onLocalDelete,
  file,
  reupload,
  isError,
  retry,
  disabled = false,
  search,
  errorMessage,
  isThirdActionVisible,
  isSoft,
  onDelete,
}: TFileProps) => {
  const { download } = useFiles();

  const canReupload = !!reupload;
  const isAdditionalInfo = readOnly || !!onLocalDelete || canReupload;

  const textClassName = clsx(styles.text, {
    [styles.error]: isError,
  });
  const descriptionClassName = clsx(styles.description, {
    [styles.error]: isError,
  });

  const errMessage = errorMessage || 'File upload failed';
  const description = isError
    ? errMessage
    : file
      ? formatDate({ date: file?.createdAt, format: 'DD/MM/YY hh:mm:ss' })
      : '-';

  const firstTooltip = isError ? 'Reload' : 'Download';

  const FirstActionIcon = isError ? IconSync : IconDownload;

  const firstAction = () => {
    if (isError) {
      retry?.();
      return;
    }
    download?.(file, invoice);
  };

  const isFirstActionVisible = isError || readOnly || canReupload;

  const secondTooltip = canReupload ? 'Upload new version' : 'Delete';

  const SecondActionIcon = canReupload ? IconRestore : IconDelete;

  const secondAction = () => {
    if (canReupload) {
      reupload?.();
      return;
    }

    onLocalDelete?.();
  };

  const isSecondActionVisible = !!onLocalDelete || (canReupload && !readOnly);

  const thirdTooltip = isSoft ? 'Remove' : 'Delete';

  return (
    <div
      className={clsx(styles.component, {
        [styles.between]: isAdditionalInfo,
      })}
    >
      <div className={styles.body}>
        {isAdditionalInfo ? (
          <>
            <IconAttachment width="16" height="16" className={styles.icon} />
            <Text className={textClassName}>{file?.fileName}</Text>
          </>
        ) : (
          <Button
            type="text"
            size="small"
            className={styles.body}
            icon={
              <IconAttachment width="16" height="16" className={styles.icon} />
            }
            onClick={(e) => {
              e.stopPropagation();
              download?.(file, invoice);
            }}
          >
            <Highlighter
              value={file?.fileName || ''}
              search={search || ''}
              className={textClassName}
            />
          </Button>
        )}
      </div>
      <div className={styles.flex}>
        {isFirstActionVisible && (
          <div className={styles.descriptionContainer}>
            <Text className={descriptionClassName}>{description}</Text>
            <Tooltip title={firstTooltip}>
              <Button
                size="small"
                type="text"
                icon={
                  <FirstActionIcon
                    width="16"
                    height="16"
                    className={styles.icon}
                  />
                }
                onClick={firstAction}
              />
            </Tooltip>
          </div>
        )}
        {isSecondActionVisible && (
          <Tooltip title={secondTooltip}>
            <Button
              size="small"
              type="text"
              className={styles.delete}
              disabled={disabled}
              icon={
                <SecondActionIcon
                  width="16"
                  height="16"
                  className={clsx(styles.icon, {
                    [styles.disabled]: disabled,
                  })}
                />
              }
              onClick={secondAction}
            />
          </Tooltip>
        )}
        {isThirdActionVisible && (
          <Tooltip title={thirdTooltip}>
            <Button
              size="small"
              type="text"
              className={styles.delete}
              disabled={disabled}
              icon={
                <IconDelete
                  width="16"
                  height="16"
                  className={clsx(styles.icon, {
                    [styles.disabled]: disabled,
                  })}
                />
              }
              onClick={onDelete}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default File;
