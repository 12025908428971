import { Form } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Mode } from 'src/components/products/drawer/ProductsDrawer';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import {
  DEFAULT_COUNTRY_ISO,
  DEFAULT_DIAL_CODE,
  UserFormFields,
  UserRoles,
} from 'src/constants/users';
import { useMessageContext } from 'src/contexts/message-context';
import { IUser } from 'src/types/general';
import { getErrorText } from 'src/utils/get-error-text';
import useUsers from './use-users';
import { getDialCodeByIso, getPhoneNumber } from 'src/utils/get-dial-code';

const INITIAL_VALUE = {
  dialCode: DEFAULT_DIAL_CODE,
};

const useUsersCrudForm = (
  userId: string | null,
  mode: Mode | null,
  onClose: () => void,
  open: boolean
) => {
  const { showErrorMessage, showSuccessMessage } = useMessageContext();

  const { createUser, updateUser, getUser } = useUsers();

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const initialValue = useRef<Partial<IUser>>(INITIAL_VALUE);

  const [form] = Form.useForm<IUser>();

  const firstName = Form.useWatch(UserFormFields.FIRST_NAME, form);
  const lastName = Form.useWatch(UserFormFields.LAST_NAME, form);
  const jobTitle = Form.useWatch(UserFormFields.JOB_TITLE, form);
  const role = Form.useWatch(UserFormFields.ROLE, form);
  const email = Form.useWatch(UserFormFields.EMAIL, form);
  const phoneNumber = Form.useWatch(UserFormFields.PHONE_NUMBER, form);
  const countryCode = Form.useWatch(UserFormFields.COUNTRY_CODE, form);
  const status = Form.useWatch(UserFormFields.STATUS, form);
  const createdAt = Form.useWatch(UserFormFields.CREATED_AT, form);
  const createdByName = Form.useWatch(UserFormFields.CREATED_BY, form);
  const modifiedAt = Form.useWatch(UserFormFields.MODIFIED_AT, form);
  const modifiedByName = Form.useWatch(UserFormFields.MODIFIED_BY, form);
  const dialCode = useMemo(() => {
    return getDialCodeByIso(countryCode);
  }, [countryCode]);

  const isChanged = useMemo(() => {
    if (
      !initialValue.current &&
      (firstName || lastName || jobTitle || role || email || phoneNumber)
    ) {
      return true;
    }
    if (initialValue.current?.firstName !== firstName) {
      return true;
    }
    if (initialValue.current?.lastName !== lastName) {
      return true;
    }
    if (initialValue.current?.jobTitle !== jobTitle) {
      return true;
    }
    if (initialValue.current?.role !== role) {
      return true;
    }
    if (initialValue.current?.email !== email) {
      return true;
    }
    if (initialValue.current?.phoneNumber !== phoneNumber) {
      if (!phoneNumber && !initialValue.current.phoneNumber) {
        return false;
      }
      return true;
    }
    if (initialValue.current?.dialCode !== dialCode) {
      return true;
    }

    return false;
  }, [firstName, lastName, jobTitle, role, email, phoneNumber, dialCode]);

  const resetForm = () => {
    form.resetFields(undefined);
    initialValue.current = INITIAL_VALUE;
    setData(null);
    setLoading(false);
  };

  useEffect(() => {
    const beforeUnloadHandler = (event: Event) => {
      event.preventDefault();

      event.returnValue = true;
    };
    if (isChanged) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [isChanged]);

  const submit = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const body = {
          [UserFormFields.FIRST_NAME]: firstName!.trim(),
          [UserFormFields.LAST_NAME]: lastName!.trim(),
          [UserFormFields.JOB_TITLE]: jobTitle?.trim() || null,
          [UserFormFields.ROLE]: role!,
          [UserFormFields.EMAIL]: email!.trim(),
          [UserFormFields.PHONE_NUMBER]: phoneNumber
            ? `${dialCode}${phoneNumber.trim()}`
            : null,
          [UserFormFields.COUNTRY_CODE]: phoneNumber
            ? countryCode
            : DEFAULT_COUNTRY_ISO,
        };
        if (mode === Mode.CREATE) {
          setLoadingText('Creating');
          try {
            const res = await createUser(body);
            if (!res.errors) {
              showSuccessMessage(`User created and invitation sent`);
            } else {
              const text = getErrorText(res.errors.response?.data.errors);
              if (text.length) {
                text.forEach((err) => {
                  showErrorMessage(err);
                });
              } else {
                showErrorMessage(`User not created`);
              }
            }
          } catch (err) {}
          closeDrawer();
          setLoading(false);
          return;
        }
        if (mode === Mode.EDIT && userId) {
          setLoadingText('Saving');
          try {
            const res = await updateUser({
              id: userId,
              ...body,
            });
            if (!res.errors) {
              showSuccessMessage('Changes saved');
            } else {
              const text = getErrorText(res.errors.response?.data.errors);
              if (text.length) {
                text.forEach((err) => {
                  showErrorMessage(err);
                });
              } else {
                showErrorMessage('Changes not saved');
              }
            }
          } catch (err) {}

          closeDrawer();
          return;
        }
      })
      .catch((err) => {
        console.log('Validation failed');
      });
  };

  const [data, setData] = useState<IUser | null>(null);

  useEffect(() => {
    const fetchDetails = async (id: string) => {
      setLoadingText('Loading');
      setLoading(true);
      const { result } = await getUser(id);
      if (result?.data) {
        const product = result.data;
        initialValue.current = product;
        setData(product);
      }
      setLoading(false);
    };
    if (userId) {
      fetchDetails(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (data) {
      const initalData = {
        [UserFormFields.FIRST_NAME]: data.fullName?.split(' ')[0],
        [UserFormFields.LAST_NAME]: data.fullName?.split(' ')[1],
        [UserFormFields.JOB_TITLE]: data.jobTitle,
        [UserFormFields.ROLE]: data.role || UserRoles.BASIC_USER,
        [UserFormFields.EMAIL]: data.email,
        [UserFormFields.PHONE_NUMBER]: getPhoneNumber(
          data.phoneNumber,
          data.countryCode
        ),
        [UserFormFields.COUNTRY_CODE]: data.countryCode || DEFAULT_COUNTRY_ISO,
        [UserFormFields.STATUS]: data.status,
        [UserFormFields.CREATED_AT]: data.createdAt,
        [UserFormFields.CREATED_BY]: data.createdByFullName,
        [UserFormFields.MODIFIED_AT]: data.modifiedAt,
        [UserFormFields.MODIFIED_BY]: data.modifiedByFullName,
      };
      form.setFieldsValue(initalData);
      initialValue.current = {
        ...initalData,
        dialCode: getDialCodeByIso(data.countryCode),
      };
    } else {
      form.setFieldValue(UserFormFields.ROLE, UserRoles.BASIC_USER);
      form.setFieldValue(UserFormFields.COUNTRY_CODE, DEFAULT_COUNTRY_ISO);
      initialValue.current = { ...INITIAL_VALUE, role: UserRoles.BASIC_USER };
    }
  }, [data, open]);

  const closeDrawer = () => {
    const broadcast = new BroadcastChannel(BROADCAST_NAME);
    resetForm();
    onClose();
    broadcast.postMessage('refresh');
    broadcast.close();
  };

  return {
    form,
    firstName,
    lastName,
    jobTitle,
    role,
    email,
    phoneNumber,
    dialCode,
    status,
    createdAt,
    createdByName,
    modifiedAt,
    modifiedByName,
    isChanged,
    loading,
    submit,
    closeDrawer,
    loadingText,
  };
};

export default useUsersCrudForm;
