import { Typography } from 'antd';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import Url from 'src/components/table/url/Url';
import styles from 'src/styles/actions.module.scss';
import { IMappedParsedInvoiceResult } from 'src/types/mappings';
import { TMappedParsedInvoiceResultRecord } from 'src/utils/map-parsed-invoice-result-table';
import { ProtectedRoutes } from './routes';

export enum ParsedInvoiceResultColumns {
  PRODUCT_NAME = 'name',
  MATERIAL_NUMBER = 'materialNumber',
  PART_NUMBER = 'partNumber',
  BARCODE = 'barCode',
  ISSUE = 'issue',
}

export const defaultParsedInvoiceResultColumns = [
  ParsedInvoiceResultColumns.PRODUCT_NAME,
  ParsedInvoiceResultColumns.MATERIAL_NUMBER,
  ParsedInvoiceResultColumns.PART_NUMBER,
  ParsedInvoiceResultColumns.BARCODE,
  ParsedInvoiceResultColumns.ISSUE,
];

export const parsedInvoiceResultColumnLabel = {
  [ParsedInvoiceResultColumns.PRODUCT_NAME]: 'Product name',
  [ParsedInvoiceResultColumns.MATERIAL_NUMBER]: 'Material number',
  [ParsedInvoiceResultColumns.PART_NUMBER]: 'Part number',
  [ParsedInvoiceResultColumns.BARCODE]: 'Barcode',
  [ParsedInvoiceResultColumns.ISSUE]: 'Issue',
};

export const parsedInvoiceResultColumnWidth = {
  [ParsedInvoiceResultColumns.PRODUCT_NAME]: 234,
  [ParsedInvoiceResultColumns.MATERIAL_NUMBER]: 150,
  [ParsedInvoiceResultColumns.PART_NUMBER]: 140,
  [ParsedInvoiceResultColumns.BARCODE]: 140,
  [ParsedInvoiceResultColumns.ISSUE]: 234,
};

export const parsedInvoiceResultColumnSortOrder: Partial<
  Record<ParsedInvoiceResultColumns, SortOrder>
> = {
  [ParsedInvoiceResultColumns.PRODUCT_NAME]: SortOrder.ASC,
};

export const parsedInvoiceResultColumnRender = {
  [ParsedInvoiceResultColumns.PRODUCT_NAME]: (
    value: IMappedParsedInvoiceResult[ParsedInvoiceResultColumns.PRODUCT_NAME],
    record: TMappedParsedInvoiceResultRecord
  ) => {
    return (
      <Cell isValue={!!value}>
        {record.productId && !record.issue ? (
          <Url
            value={`${ProtectedRoutes.PRODUCTS}/${record.isMyProduct ? record.productId : record.productOfferId}?isSupplier=${!record.isMyProduct}`}
            iconShown={false}
          >
            {value}
          </Url>
        ) : (
          value
        )}
      </Cell>
    );
  },
  [ParsedInvoiceResultColumns.MATERIAL_NUMBER]: (
    value: IMappedParsedInvoiceResult[ParsedInvoiceResultColumns.MATERIAL_NUMBER]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [ParsedInvoiceResultColumns.PART_NUMBER]: (
    value: IMappedParsedInvoiceResult[ParsedInvoiceResultColumns.PART_NUMBER]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [ParsedInvoiceResultColumns.BARCODE]: (
    value: IMappedParsedInvoiceResult[ParsedInvoiceResultColumns.BARCODE]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [ParsedInvoiceResultColumns.ISSUE]: (
    value: IMappedParsedInvoiceResult[ParsedInvoiceResultColumns.ISSUE]
  ) => {
    return <Typography.Text className={styles.issue}>{value}</Typography.Text>;
  },
};
