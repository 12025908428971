import { Typography, theme } from 'antd';
import clsx from 'clsx';
import { Logo } from 'src/components/logo/Logo';
import SignInModalHeader from 'src/components/sign-in/modal/header/SignInModalHeader';
import useSignUpForm from 'src/hooks/use-signup-form';
import { IValidateTokenResult } from 'src/types/general';
import SignUpForm from '../form/SignUpForm';
import styles from './SignUpModal.module.scss';

const { Title } = Typography;

type TSignUpModalProps = {
  className?: string;
  userInfo: IValidateTokenResult;
  invitationId: string;
};

const SignUpModal = ({
  className,
  userInfo,
  invitationId,
}: TSignUpModalProps) => {
  const {
    token: {
      colorBgContainer,
      boxShadowTertiary,
      borderRadiusLG,
      paddingXL,
      paddingLG,
      marginXS,
    },
  } = theme.useToken();

  const {
    step,
    goBack,
    loading,
    postError,
    form,
    company,
    password,
    submit,
    mounted,
    passwordConfirm,
    firstName,
    lastName,
    isPasswordValid,
    getValidationStatus,
    validationStatus,
    handleChange,
  } = useSignUpForm(
    invitationId,
    userInfo.email,
    userInfo.isCompanyRegistration ? 1 : 2,
    userInfo.companyName
  );

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.transitionIn]: mounted,
        },
        className
      )}
      style={{
        backgroundColor: colorBgContainer,
        boxShadow: boxShadowTertiary,
        borderRadius: borderRadiusLG,
        gap: paddingLG,
        padding: paddingXL,
      }}
    >
      {userInfo.isCompanyRegistration && (
        <SignInModalHeader step={step} goBack={goBack} />
      )}
      <div>
        <Title level={3} className={styles.title}>
          Welcome to
        </Title>
        <Logo
          style={{
            marginTop: -marginXS,
            marginBottom: 18,
          }}
          width="188"
          height="47"
        />
        <SignUpForm
          step={step}
          form={form}
          company={company}
          firstName={firstName}
          lastName={lastName}
          password={password}
          passwordConfirm={passwordConfirm}
          submit={submit}
          postError={postError}
          loading={loading}
          userInfo={userInfo}
          getValidationStatus={getValidationStatus}
          isPasswordValid={isPasswordValid}
          validationStatus={validationStatus}
          handleChange={handleChange}
          invitationId={invitationId}
        />
      </div>
    </div>
  );
};

export default SignUpModal;
