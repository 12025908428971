import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { useNavigationContext } from 'src/contexts/navigation-context';
import { ITableParams } from 'src/types/generic';
import {
  getAssignedProductsColumnSetting,
  getMappedProductOffersData,
} from 'src/utils/map-assigned-product-table';
import useCompanies from './use-companies';
import useProductOfferTableForm from './use-product-offer-table-form';
import useTableWidth from './use-table-width';

const useSupplierDetails = () => {
  const { supplier_id } = useParams();

  const supplierId = supplier_id || '';

  const { getSupplier, getSupplierProducts, exportProductOffers } =
    useCompanies();

  const { setTitle } = useNavigationContext();

  const { getAllMeasurements, loading: measurementsLoading } =
    useMeasurementsContext();

  const { form, data, isEditing, uoms, currencies, setData } =
    useProductOfferTableForm();

  const [supplierName, setSupplierName] = useState<string | null>('');
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'createdAt',
    sortOrder: SortOrder.DESC,
  });

  const columns = useMemo(() => {
    const res = getAssignedProductsColumnSetting({
      uoms,
      actionsHidden: !total,
      currencies,
      form,
      isHistoryAction: true,
    });
    return res;
  }, [tableParams, uoms, currencies, isEditing, total]);

  const fetchProducts = async () => {
    setLoading(true);
    const productsResult = await getSupplierProducts(supplierId, tableParams);
    const productsResponse = productsResult.result?.data;

    if (productsResponse) {
      setData(getMappedProductOffersData(productsResponse.data));
      setTotal(productsResponse.totalCount);
    }
    const customerResult = await getSupplier(supplierId);
    const customerResponse = customerResult.result?.data;

    if (customerResponse) {
      setSupplierName(customerResponse.name);
      setTitle([customerResponse.name]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllMeasurements();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [tableParams]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const tableWidth = useTableWidth(columns);

  const exportProducts = async () => {
    await exportProductOffers(
      {
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: total,
        },
      },
      supplierId
    );
  };

  return {
    handleTableChange,
    loading: loading || measurementsLoading,
    columns,
    width: tableWidth,
    data,
    total,
    supplierName,
    form,
    exportProducts,
  };
};

export default useSupplierDetails;
