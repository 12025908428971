import { SortOrder } from 'src/components/icons/sort/IconSort';
import { ITableParams, ITableParamsWithSearch } from 'src/types/generic';

export const getParamsWithSearch = (data: ITableParamsWithSearch) => {
  const params = getParams(data);

  if (data.search) {
    params.append('Search', data.search);
  }

  return params;
};

export const getParams = (data: ITableParams) => {
  const params = new URLSearchParams();
  params.append('Page', `${data.pagination?.current}`);
  params.append('PageSize', `${data.pagination?.pageSize}`);
  if (data.sortField) {
    params.append('SortBy', `${data.sortField}`);
    params.append('Descending', `${data.sortOrder === SortOrder.DESC}`);
  }

  return params;
};
