import { Button, Form, FormInstance, Input, Tag } from 'antd';
import FormItem from 'src/components/form-item/FormItem';
import PhoneInput from 'src/components/phone-input/PhoneInput';
import Select from 'src/components/select/Select';
import Cell from 'src/components/table/cell/Cell';
import { EMAIL_REGEX } from 'src/constants/regex';
import {
  UserFormFields,
  UserRoles,
  userFormLabels,
  userRolesLabels,
  userStatusColor,
  userStatusLabel,
} from 'src/constants/users';
import {
  EXISTING_EMAIL,
  INCORECT_EMAIL,
  REQUIRED,
} from 'src/constants/validation';
import useUsers from 'src/hooks/use-users';
import { IUser } from 'src/types/general';
import { formatDate } from 'src/utils/date-formatter';
import { Mode } from '../UsersDrawer';
import styles from './UsersDrawerForm.module.scss';
import { requiredValidator } from 'src/utils/validators';

type TUsersDrawerFormProps = {
  mode: Mode | null;
  onClose: () => void;
  submit: () => void;
  isChanged: boolean;
  form: FormInstance<any>;
  dialCode: string;
} & Partial<IUser>;

const UsersDrawerForm = ({
  mode,
  onClose,
  form,
  isChanged,
  submit,
  firstName,
  lastName,
  createdAt,
  createdByFullName,
  modifiedAt,
  modifiedByFullName,
  email,
  status,
  dialCode,
}: TUsersDrawerFormProps) => {
  const addMode = mode === Mode.CREATE;

  const { validateEmail } = useUsers();

  const label = addMode ? 'Create' : 'Save';

  return (
    <Form form={form} layout="vertical" requiredMark={'optional'}>
      <div className={styles.row}>
        <FormItem
          className={styles.rowItem}
          label={userFormLabels[UserFormFields.FIRST_NAME]}
          name={UserFormFields.FIRST_NAME}
          isFeedbackShown={!firstName}
          rules={[
            {
              required: true,
              validator: requiredValidator,
              message: REQUIRED,
            },
            { max: 50 },
          ]}
          hasFeedback
        >
          <Input suffix={!firstName ? undefined : <span />} />
        </FormItem>
        <FormItem
          className={styles.rowItem}
          label={userFormLabels[UserFormFields.LAST_NAME]}
          name={UserFormFields.LAST_NAME}
          isFeedbackShown={!lastName}
          rules={[
            {
              required: true,
              validator: requiredValidator,
              message: REQUIRED,
            },
            { max: 50 },
          ]}
          hasFeedback
        >
          <Input suffix={!lastName ? undefined : <span />} />
        </FormItem>
      </div>
      <FormItem
        label={userFormLabels[UserFormFields.JOB_TITLE]}
        name={UserFormFields.JOB_TITLE}
        rules={[{ max: 150 }]}
      >
        <Input />
      </FormItem>
      <div className={styles.row}>
        <FormItem
          className={styles.rowItem}
          label={userFormLabels[UserFormFields.ROLE]}
          name={UserFormFields.ROLE}
          rules={[{ required: true, message: REQUIRED }]}
        >
          <Select
            options={[
              {
                value: UserRoles.BASIC_USER,
                label: userRolesLabels[UserRoles.BASIC_USER],
              },
              {
                value: UserRoles.ADVANCED_USER,
                label: userRolesLabels[UserRoles.ADVANCED_USER],
              },
            ]}
          />
        </FormItem>
        <div className={styles.rowItem} />
      </div>
      <FormItem
        label={userFormLabels[UserFormFields.EMAIL]}
        name={UserFormFields.EMAIL}
        rules={[
          { required: true, message: REQUIRED },
          { max: 150 },
          {
            validator: async (_, value) => {
              if (!value || !addMode) {
                return Promise.resolve();
              }
              const isValid = EMAIL_REGEX.test(value as string);
              if (!isValid) {
                return Promise.reject(INCORECT_EMAIL);
              }
              const validationResult = await validateEmail(value);

              if (validationResult.errors) {
                return Promise.reject(EXISTING_EMAIL);
              }

              return Promise.resolve();
            },
          },
        ]}
        validateDebounce={500}
        isFeedbackShown={!email}
        hasFeedback
      >
        <Input disabled={!addMode} suffix={!email ? undefined : <span />} />
      </FormItem>

      <PhoneInput max={16 - dialCode.length} />

      {!addMode && (
        <div>
          <FormItem
            label={userFormLabels[UserFormFields.STATUS]}
            name={UserFormFields.STATUS}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Cell isValue={!!status}>
              <Tag color={userStatusColor[status!]}>
                {userStatusLabel[status!]}
              </Tag>
            </Cell>
          </FormItem>

          <div className={styles.info}>
            <FormItem
              className={styles.label}
              label={userFormLabels[UserFormFields.CREATED_AT]}
              name={UserFormFields.CREATED_AT}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cell isValue={!!createdAt}>
                {formatDate({ date: createdAt })}
              </Cell>
            </FormItem>
            <FormItem
              className={styles.label}
              label={userFormLabels[UserFormFields.CREATED_BY]}
              name={UserFormFields.CREATED_BY}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cell isValue={!!createdByFullName}>{createdByFullName}</Cell>
            </FormItem>
            <FormItem
              className={styles.label}
              label={userFormLabels[UserFormFields.MODIFIED_AT]}
              name={UserFormFields.MODIFIED_AT}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cell isValue={!!modifiedAt}>
                {formatDate({ date: modifiedAt })}
              </Cell>
            </FormItem>
            <FormItem
              className={styles.label}
              label={userFormLabels[UserFormFields.MODIFIED_BY]}
              name={UserFormFields.MODIFIED_BY}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cell isValue={!!modifiedByFullName}>{modifiedByFullName}</Cell>
            </FormItem>
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!isChanged && mode === Mode.EDIT}
          onClick={submit}
          type="primary"
        >
          {label}
        </Button>
      </div>
    </Form>
  );
};

export default UsersDrawerForm;
