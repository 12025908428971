import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddSupplierModal from 'src/components/add-supplier-modal/AddSupplierModal';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import { IconMail } from 'src/components/icons/mail/IconMail';
import InviteModal from 'src/components/invite-modal/InviteModal';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { ProtectedRoutes, RouteTitle } from 'src/constants/routes';
import { NO_RESULTS, NO_SUPPLIERS_ADDED } from 'src/constants/validation';
import useSuppliersList from 'src/hooks/use-suppliers-list';
import { TMappedSupplierRecord } from 'src/utils/map-companies-table';
import styles from './SupplierList.module.scss';

const SupplierList = () => {
  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    total,
    debouncedSearch,
    open,
    setOpen,
    search,
    current,
    basicUser,
    isAdmin,
  } = useSuppliersList();

  const navigate = useNavigate();

  const [invite, setInvite] = useState(false);

  return (
    <div>
      <TableHeader
        search={search}
        title={RouteTitle[ProtectedRoutes.SUPPLIERS]}
        bold={false}
        searchPlaceholder={'GLN, Supplier ID, name, address'}
        setSearch={setSearch}
        primaryActionTitle={'Add hive supplier'}
        primaryActionIcon={<IconAdd width="16" height="16" />}
        primaryAction={() => {
          setOpen(true);
        }}
        noActions={basicUser}
        onlyPrimaryAction={!isAdmin}
        secondaryActionTitle={'Invite supplier'}
        secondaryActionIcon={<IconMail width="16" height="16" />}
        secondaryAction={() => {
          setInvite(true);
        }}
      />
      <Table<TMappedSupplierRecord>
        emptyTextDescription={debouncedSearch ? NO_RESULTS : NO_SUPPLIERS_ADDED}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        width={width}
        height={'calc(100vh - 264px)'}
        rowClassName={() => {
          return styles.interactive;
        }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ProtectedRoutes.SUPPLIERS}/${record.id}`);
          },
        })}
        total={total}
        current={current}
      />
      <AddSupplierModal onClose={() => setOpen(false)} visible={open} />
      <InviteModal
        visible={invite}
        isCustomer={false}
        onClose={() => {
          setInvite(false);
        }}
      />
    </div>
  );
};

export default SupplierList;
