import { Typography } from 'antd';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import styles from 'src/styles/actions.module.scss';
import { ITableParams } from 'src/types/generic';
import useCompanies from './use-companies';
import useDebouncer from './use-debouncer';

const useSupplierFilters = (supplierTableParams: ITableParams) => {
  const { getSuppliers } = useCompanies();

  const [filters, setFilters] = useState<ColumnFilterItem[]>([]);
  const [filterSearch, setFilterSearch] = useState('');
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
  const [debouncedFilterSearch, setDebouncedFilterSearch] = useState('');

  useDebouncer(setDebouncedFilterSearch, filterSearch);

  useEffect(() => {
    const fetchSuppliers = async () => {
      if (!!debouncedFilterSearch && debouncedFilterSearch.length < 3) {
        return;
      }
      setFiltersLoading(true);
      const res = await getSuppliers(
        {
          pagination: {
            current: 1,
            pageSize: 10,
          },
          sortField: 'name',
          sortOrder: SortOrder.ASC,
          search: debouncedFilterSearch,
        },
        '/report/suppliers/paged'
      );

      if (res.result?.data) {
        const filters = res.result.data.data.map((supplier) => ({
          text: (
            <div className={styles.filter}>
              <Typography.Text className={styles.filterTitle}>
                {supplier.name}
              </Typography.Text>
              <Typography.Text
                className={styles.filterDescription}
              >{`${supplier.gln || '-'} | ${supplier.supplierPersonalId || '-'}`}</Typography.Text>
            </div>
          ),
          value: supplier.id,
          description: supplier.name,
        }));

        setFilters(filters);
      }
      setFiltersLoading(false);
    };
    fetchSuppliers();
  }, [debouncedFilterSearch]);

  const sortedFilters = useMemo(() => {
    const selectedValues = supplierTableParams.filters?.['supplier.name'];

    const res = filters.slice();

    if (!selectedValues) {
      return res;
    }
    const sortedFilters = res.sort((a, b) => {
      const aValue = a.value as React.Key;
      const bValue = b.value as React.Key;

      const isASelected = selectedValues.includes(aValue);
      const isBSelected = selectedValues.includes(bValue);
      return Number(isBSelected) - Number(isASelected);
    });

    return sortedFilters;
  }, [supplierTableParams.filters, filters]);

  return {
    sortedFilters,
    setFilterSearch,
    filtersLoading,
    debouncedFilterSearch,
  };
};

export default useSupplierFilters;
