import {
  HTTP,
  TResponse,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TGetInvoicesParams,
  TGetInvoicesResponse,
  TGetParsedInvoiceResultParams,
  TGetParsedInvoiceResultResponse,
} from 'src/types/backend';
import { getParams, getParamsWithSearch } from 'src/utils/get-params';

export type TGetInvoices = (
  data: TGetInvoicesParams,
  abortController?: AbortController | null
) => Promise<TResponse<TGetInvoicesResponse, unknown>>;

const useSources = () => {
  const { fetch } = useNetworkContext();

  const getInvoices: TGetInvoices = async (data, abortController) => {
    const params = getParamsWithSearch(data);
    const result = await fetch<TGetInvoicesResponse, unknown>({
      url: '/invoice/paged',
      method: HTTP.GET,
      signal: abortController?.signal || undefined,
      params,
    });

    return result;
  };

  const deleteInvoice = async (invoiceId: string) => {
    const result = await fetch<unknown, unknown>({
      url: `/invoice/${invoiceId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const getParsedInvoiceResult = async (
    id: string,
    data: TGetParsedInvoiceResultParams
  ) => {
    const params = getParams(data);
    params.append('ShowInvalidDataOnly', `${data.showInvalidDataOnly}`);
    const result = await fetch<TGetParsedInvoiceResultResponse, unknown>({
      url: `/invoice/${id}/productProcessingResult/paged`,
      method: HTTP.GET,
      params,
    });

    return result;
  };

  return {
    getInvoices,
    deleteInvoice,
    getParsedInvoiceResult,
  };
};

export default useSources;
