import { FileStatus } from 'src/constants/invoices';
import { UserRoles, UserStatus } from 'src/constants/users';

export interface IFile {
  blobName: string | null;
  fileName: string | null;
  createdAt: Date;
}

export interface IEpd extends IFile {
  epdId: string;
  hiveStatus: FileStatus;
}

export interface IInvoice extends Omit<IFile, 'createdAt'> {
  purchaseOrderNumber: string | null;
  invoiceDate: Date | null;
}
export interface IProduct {
  id: string;
  name: string | null;
  materialNumber: string | null;
  partNumber: string | null;
  barCode: string | null;
  externalEpdUrl: string | null;
  unitOfMeasurement: string | null;
  unitOfMeasurementId: string | null;
  rrpPrice: number | null;
  quantity: number | null;
  currency: number;
  canEdit: boolean;
  canDelete: boolean;
  epdFile: IEpd | null;
  createdAt: Date;
  createdByName: string | null;
  modifiedAt: Date;
  modifiedByName: string | null;
}

export interface IAction {
  resource: string;
  action: string;
}

export enum Permission {
  ADMIN = 'Admin',
  ADVANCED_USER = 'AdvancedUser',
  BASIC_USER = 'BasicUser',
}

export interface IPermisson {
  name: Permission;
  permissions: IAction[];
}

export interface ICompany {
  id: string;
  name: string | null;
  gln: string | null;
  industry: string | null;
  status: number;
  address: string | null;
  phoneNumber: string | null;
  primaryContact: string | null;
  primaryEmail: string | null;
  secondaryContact: string | null;
  secondaryEmail: string | null;
  isConnected: boolean;
}

export interface ISupplier extends Omit<ICompany, 'isConnected' | 'status'> {
  connectionId: string;
  supplierPersonalId: string | null;
}
export interface ICustomer extends Omit<ISupplier, 'supplierPersonalId'> {
  isDeleted: boolean;
}

export interface IMeasurement {
  id: string;
  shortName: string | null;
  name: string | null;
}

export interface ICurrency {
  value: number;
  name: string | null;
}

export interface IProductOffer {
  id: string;
  product: Pick<
    IProduct,
    | 'id'
    | 'name'
    | 'materialNumber'
    | 'partNumber'
    | 'barCode'
    | 'epdFile'
    | 'externalEpdUrl'
  > & {
    isDeleted: boolean;
  };
  customer: Pick<ICustomer, 'id' | 'name' | 'gln' | 'industry'>;
  supplier: Pick<ISupplier, 'id' | 'name' | 'gln' | 'industry'>;
  productOfferPrice: {
    price: number;
    currency: number;
    startDate: Date | null;
    expirationDate: Date | null;
  } | null;
  unitOfMeasurement: string | null;
  unitOfMeasurementId: string | null;
  quantity: number;
  createdAt: Date;
  modifiedByName: string | null;
  canEdit: boolean;
  canDelete: boolean;
}

export interface IProductOfferDetails
  extends Omit<IProductOffer, 'company' | 'canEdit' | 'canDelete'> {
  epdFile: IFile | null;
  externalEpdUrl: string | null;
}

export interface IPurchaseHistory {
  id: string;
  quantity: number;
  currency: number;
  unitOfMeasurementId: string | null;
  unitOfMeasurement: string | null;
  totalAmount: number;
  invoice: IInvoice | null;
  productOffer: {
    id: string;
    price: number;
    currency: number;
  } | null;
  project: {
    id: string;
    personalId: string | null;
    name: string | null;
  } | null;
}

export interface ICustomerMetric {
  total: number;
  coupled: number;
  decoupled: number;
  pending: number;
  supplierId: string;
}

export interface ISupplierMetric {
  total: number;
  coupled: number;
  decoupled: number;
  pending: number;
  tenantId: string;
}

export interface IDashboardMetrics {
  suppliersStatus: ISupplierMetric;
  customersStatus: ICustomerMetric;
  tenantProductsStatus: {
    total: number;
    active: number;
    dataQualityIssues: number;
    tenantId: string;
  };
  epdFiles: {
    epdTotal: number;
    productsWithEpd: number;
    productsWithoutEpd: number;
    tenantId: string;
  };
  tenantProductsArchived: {
    archived: number;
    tenantId: string;
  };
  tenantProductsPriceExpiration: {
    nonExpired: number;
    expiringIn7Days: number;
    expired: number;
    tenantId: string;
  };
  tenantTopSellingProducts: {
    sellingCount: number;
    productName: string;
    productId: string;
    tenantId: string;
  }[];
  supplierProductsStatus: {
    total: number;
    active: number;
    archived: number;
    customerCompanyId: string;
  };
  supplierProductsPriceExpiration: {
    nonExpired: number;
    expiringIn7Days: number;
    expired: number;
    customerCompanyId: string;
  };
  supplierTopPurchasedProducts: {
    purchasedCount: number;
    productName: string;
    productId: string;
    supplierCompanyId: string;
  }[];
  favouriteReports: {
    name: string;
    lastCreated: Date;
    tenantId: string;
  }[];
}

export interface IInvoiceRecord extends IInvoice {
  id: string;
  hiveStatus: FileStatus;
  supplier: Pick<ISupplier, 'id' | 'name' | 'gln' | 'industry'>;
  customer: Pick<ICustomer, 'id' | 'name' | 'gln' | 'industry'>;
  createdAt: Date;
  createdBy: string;
  tenantId: string;
  createdByFullName: string | null;
  createdByEmail: string | null;
  canDelete: boolean;
  isDeleted: boolean;
}

export interface IIndicator {
  id: string;
  shortName: string | null;
  name: string | null;
  unitOfMeasurement: IMeasurement;
}

export interface IProject {
  id: string;
  personalId: string | null;
  name: string | null;
  companyId: string | null;
}

export interface IStage {
  id: string;
  name: string | null;
}
export interface ISustainabilityReport {
  requestRecordId: string;
  createdAt: Date;
  tenantId: string;
  product: Pick<
    IProduct,
    'id' | 'name' | 'materialNumber' | 'partNumber' | 'barCode'
  >;
  supplier: Pick<ISupplier, 'id' | 'name'>;
  project: Omit<IProject, 'companyId'>;
  stage: IStage | null;
  invoice:
    | (Pick<
        IInvoiceRecord,
        'id' | 'fileName' | 'blobName' | 'purchaseOrderNumber' | 'invoiceDate'
      > & {
        quantity: number;
        unitOfMeasurement: IMeasurement;
      })
    | null;
  epd: IFile;
  environmentalIndicatorsEffortValues: Array<
    Pick<IIndicator, 'id' | 'name'> & {
      value: number;
    }
  >;
}

export interface IStatusAction {
  action: string;
  status: UserStatus;
}

export interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName: string | null;
  jobTitle: string | null;
  dialCode: string | null;
  email: string | null;
  companyName: string | null;
  phoneNumber: string | null;
  countryCode: string | null;
  lastActiveAt: Date;
  status: UserStatus;
  role: UserRoles | null;
  createdAt: Date;
  createdByFullName: string | null;
  modifiedAt: Date;
  modifiedByFullName: string | null;
  canEdit: boolean;
  canInvite: boolean;
  statusActions: IStatusAction[];
}

export interface IIssuePriority {
  id: string;
  name: string;
}

export interface IIssueType {
  id: string;
  name: string;
}

export interface IParsedInvoiceResult {
  name: string | null;
  materialNumber: string | null;
  partNumber: string | null;
  barCode: string | null;
  issue: string | null;
  productId: string | null;
  productOfferId: string | null;
  isMyProduct: boolean;
}

export interface IValidateTokenResult {
  companyName: string | null;
  email: string;
  registrationToken: string;
  expiresIn: number;
  isCompanyRegistration: boolean;
  senderName: string;
  senderCompany: string;
  senderEmail: string;
}

export interface IValidateTokenError {
  message: string;
  senderEmail: string | null;
  senderName: string | null;
}

export interface IIndustry {
  id: string;
  name: string;
}
