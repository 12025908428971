import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SignUpValidationModal from 'src/components/sign-up/validation-modal/SignUpValidationModal';
import { Routes } from 'src/constants/routes';
import styles from './SignUp.module.scss';
import useIdentity from 'src/hooks/use-identity';
import { IValidateTokenResult } from 'src/types/general';
import SignUpModal from 'src/components/sign-up/modal/SignUpModal';

const SignUp: React.FC = () => {
  const { validateTokem } = useIdentity();

  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');

  const navigate = useNavigate();

  const [validating, setValidating] = useState(true);
  const [failed, setFailed] = useState(false);
  const [userInfo, setUserInfo] = useState<IValidateTokenResult | null>(null);
  const [senderName, setSenderName] = useState<string | null>(null);
  const [senderEmail, setSenderEmail] = useState<string | null>(null);

  useEffect(() => {
    const validate = async () => {
      if (!invitationId) {
        navigate(Routes.SIGNIN);
        return;
      }
      setValidating(true);
      const res = await validateTokem(invitationId);
      setValidating(false);
      if (res.errors) {
        if (res.errors.status === 500) {
          navigate(Routes.SIGNIN);
          return;
        }
        if (!res.errors.response?.data.senderEmail) {
          navigate(Routes.SIGNIN);
          return;
        }
        const error = res.errors.response.data;
        setFailed(true);
        setSenderEmail(error.senderEmail);
        setSenderName(error.senderName);
      }
      if (res.result?.data) {
        setUserInfo(res.result.data);
      }
    };
    validate();
  }, [invitationId]);

  return (
    <div className={styles.container}>
      {validating && <Spin size="large" className={styles.spin} />}
      {!validating && failed && (
        <SignUpValidationModal email={senderEmail} name={senderName} />
      )}
      {!validating && !failed && !!userInfo && !!invitationId && (
        <SignUpModal userInfo={userInfo} invitationId={invitationId} />
      )}
    </div>
  );
};

export default SignUp;
