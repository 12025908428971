import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import ModalWrapper from '../modal/wrapper/ModalWrapper';
import styles from './InviteModal.module.scss';
import InviteModalForm from './form/InviteModalForm';
import { Button } from 'antd';

type TInviteModalProps = {
  onClose: () => void;
  visible: boolean;
  isCustomer: boolean;
};

const InviteModal = ({ onClose, visible, isCustomer }: TInviteModalProps) => {
  const [emailPreview, setEmailPreview] = useState('');

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow?.document;

      iframeDocument?.open();
      iframeDocument?.write(emailPreview);
      iframeDocument?.close();
    }
  }, [emailPreview]);

  return (
    <ModalWrapper
      visible={visible}
      width={emailPreview ? 800 : 520}
      minHeight={emailPreview ? 640 : 538}
      maxHeight={710}
      title={
        emailPreview
          ? 'Preview email'
          : `Invite ${isCustomer ? 'customer' : 'supplier'}`
      }
      close={emailPreview ? () => setEmailPreview('') : onClose}
    >
      <div
        className={clsx(styles.container, {
          [styles.hidden]: !!emailPreview,
        })}
      >
        <InviteModalForm
          className={styles.content}
          onClose={onClose}
          isCustomer={isCustomer}
          setEmailPreview={setEmailPreview}
        />
      </div>
      <iframe
        ref={iframeRef}
        style={{
          width: '100%',
          height: '524px',
          border: 'none',
          display: emailPreview ? 'initial' : 'none',
        }}
        title="Email Preview"
      />
      {!!emailPreview && (
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            onClick={() => {
              setEmailPreview('');
            }}
          >
            OK
          </Button>
        </div>
      )}
    </ModalWrapper>
  );
};

export default InviteModal;
