import { useEffect, useMemo, useState } from 'react';
import {
  StaticReportColumns,
  reportsColumnLabel,
} from 'src/constants/sustainability-report';
import { IIndicator } from 'src/types/general';
import { TReportColumns } from 'src/utils/table-configuration';

const useIndicatorsCheckboxes = (
  indicators: IIndicator[],
  initialIndicatorColumns: TReportColumns
) => {
  const indicatorOptions = useMemo(() => {
    return [
      {
        label: reportsColumnLabel[StaticReportColumns.STAGE],
        columns: [StaticReportColumns.STAGE],
        value: StaticReportColumns.STAGE,
        disabled: true,
      },
      ...indicators.map((indicator) => ({
        label: `${indicator.name}, ${indicator.unitOfMeasurement?.shortName}`,
        columns: [indicator.id],
        value: indicator.id,
        disabled: false,
      })),
    ];
  }, [indicators]);

  const initialColumns = useMemo(
    () =>
      indicatorOptions
        .filter((indicator) =>
          initialIndicatorColumns.some((column) => column === indicator.value)
        )
        .map((el) => el.value),
    [indicatorOptions, initialIndicatorColumns]
  );

  const initialSelectedIndicators = useMemo(
    () =>
      indicatorOptions.map((indicator) =>
        initialIndicatorColumns.some((column) => column === indicator.value)
      ),
    [indicatorOptions, initialIndicatorColumns]
  );

  const [selectedIndicator, setSelectedIndicator] = useState<boolean[]>([]);

  const [indicatorColumns, setIndicatorColumns] = useState<string[]>([]);

  useEffect(() => {
    setIndicatorColumns(initialColumns);
  }, [initialColumns]);

  useEffect(() => {
    setSelectedIndicator(initialSelectedIndicators);
  }, [initialSelectedIndicators]);

  useEffect(() => {
    if (!selectedIndicator.length) {
      return;
    }
    const res = indicatorOptions.reduce((acc, option, i) => {
      if (selectedIndicator[i]) {
        return [...acc, ...option.columns];
      }
      return acc;
    }, [] as string[]);
    setIndicatorColumns(res);
  }, [selectedIndicator]);

  useEffect(() => {
    const isStageRemaining =
      indicatorColumns.length === 1 &&
      indicatorColumns[0] === StaticReportColumns.STAGE;

    const isStageListed = indicatorColumns.some(
      (value) => value === StaticReportColumns.STAGE
    );

    if (isStageRemaining) {
      setSelectedIndicator((list) => {
        list[0] = false;
        return [...list];
      });
    } else if (!isStageListed && indicatorColumns.length) {
      setSelectedIndicator((list) => {
        list[0] = true;
        return [...list];
      });
    }
  }, [indicatorColumns]);

  const allIndicatorsChecked = indicatorOptions.map(() => true);
  const allIndicatorsUnchecked = indicatorOptions.map(() => false);
  const isAllChecked =
    allIndicatorsChecked.length ===
    selectedIndicator.filter((value) => value).length;

  return {
    indicatorOptions,
    indicatorColumns,
    setSelectedIndicator,
    allIndicatorsChecked,
    allIndicatorsUnchecked,
    isAllChecked,
  };
};

export default useIndicatorsCheckboxes;
