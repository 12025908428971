import { useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { ParsedInvoiceResultColumns } from 'src/constants/parsed-invoices-result';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedParsedInvoiceResultRecord,
  getMappedParsedInvoiceResultData,
  getParsedInvoiceResultColumnSetting,
} from 'src/utils/map-parsed-invoice-result-table';
import useSources from './use-sources';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';

const useParsedInvoiceResultList = (id: string | null) => {
  const { getParsedInvoiceResult } = useSources();

  const [data, setData] = useState<TMappedParsedInvoiceResultRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorsOnly, setErrorsOnly] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const fetchResult = async (tableParams: ITableParamsWithSearch) => {
    if (!id) {
      return;
    }
    setLoading(true);
    const result = await getParsedInvoiceResult(id, {
      ...tableParams,
      showInvalidDataOnly: errorsOnly,
    });
    if (result.result?.data.data) {
      setData(getMappedParsedInvoiceResultData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, handleTableChange } = useTableSettings({
    sortField: ParsedInvoiceResultColumns.PRODUCT_NAME,
    sortOrder: SortOrder.ASC,
    fetcher: fetchResult,
  });

  useEffect(() => {
    fetchResult(tableParams);
  }, [errorsOnly]);

  const columns = useMemo(() => {
    const res = getParsedInvoiceResultColumnSetting();
    return res;
  }, [tableParams]);

  const tableWidth = useTableWidth(columns);

  return {
    columns,
    data,
    total,
    handleTableChange,
    loading,
    width: tableWidth,
    current: tableParams.pagination?.current,
    setErrorsOnly,
  };
};

export default useParsedInvoiceResultList;
