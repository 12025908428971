import { Button, Form, Input } from 'antd';
import FormItem from 'src/components/form-item/FormItem';
import Loader from 'src/components/loader/Loader';
import { InviteFormFields, inviteFormLabels } from 'src/constants/invite';
import { EMAIL_REGEX } from 'src/constants/regex';
import { INCORECT_EMAIL, REQUIRED } from 'src/constants/validation';
import useInviteForm from 'src/hooks/use-invite-form';
import { requiredValidator } from 'src/utils/validators';
import styles from './InviteModalForm.module.scss';

type TInviteModalFormProps = {
  className?: string;
  onClose: () => void;
  isCustomer: boolean;
  setEmailPreview: (value: string) => void;
};

const InviteModalForm = ({
  className,
  onClose,
  isCustomer,
  setEmailPreview,
}: TInviteModalFormProps) => {
  const {
    form,
    submit,
    loading,
    company,
    email,
    fetchPreview,
    emailError,
    companyError,
    setCompanyError,
    setEmailError,
  } = useInviteForm({ isCustomer, setEmailPreview, onClose });

  return (
    <>
      <Form form={form} layout="vertical" requiredMark className={className}>
        <div className={styles.row}>
          <FormItem
            className={styles.rowItem}
            label={inviteFormLabels[InviteFormFields.COMPANY_NAME]}
            name={InviteFormFields.COMPANY_NAME}
            isFeedbackShown={!company}
            validateStatus={companyError ? 'error' : undefined}
            help={companyError || undefined}
            rules={[
              {
                required: true,
                validator: requiredValidator,
                message: REQUIRED,
              },
              { max: 50 },
            ]}
            hasFeedback
          >
            <Input
              onChange={() => {
                setCompanyError('');
              }}
              suffix={!company ? undefined : <span />}
            />
          </FormItem>
          <FormItem
            className={styles.rowItem}
            label={inviteFormLabels[InviteFormFields.EMAIL]}
            name={InviteFormFields.EMAIL}
            isFeedbackShown={!email}
            validateStatus={emailError ? 'error' : undefined}
            help={emailError || undefined}
            rules={[
              {
                required: true,
                validator: requiredValidator,
                message: REQUIRED,
              },
              { max: 150 },
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const isValid = EMAIL_REGEX.test(value as string);
                  if (!isValid) {
                    return Promise.reject(INCORECT_EMAIL);
                  }
                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <Input
              onChange={() => {
                setEmailError('');
              }}
              suffix={!email ? undefined : <span />}
            />
          </FormItem>
        </div>
        <FormItem
          label={inviteFormLabels[InviteFormFields.SUBJECT]}
          name={InviteFormFields.SUBJECT}
          rules={[
            {
              required: true,
              validator: requiredValidator,
              message: REQUIRED,
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label={inviteFormLabels[InviteFormFields.TITLE]}
          name={InviteFormFields.TITLE}
        >
          <Input />
        </FormItem>
        <FormItem
          label={inviteFormLabels[InviteFormFields.EMAIL_BODY]}
          name={InviteFormFields.EMAIL_BODY}
        >
          <Input.TextArea className={styles.body} />
        </FormItem>
      </Form>
      <div className={styles.footer}>
        <Button disabled={loading} onClick={fetchPreview}>
          Preview Email
        </Button>
        <div className={styles.submitContainer}>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              await submit();
            }}
          >
            Invite
          </Button>
        </div>
      </div>

      <Loader visible={loading} />
    </>
  );
};

export default InviteModalForm;
