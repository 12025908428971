import { Typography, Switch, Button } from 'antd';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import Table from 'src/components/table/Table';
import { NO_PARSED_RESULTS } from 'src/constants/validation';
import useParsedInvoiceResultList from 'src/hooks/use-parsed-invoice-result-list';
import { TMappedParsedInvoiceResultRecord } from 'src/utils/map-parsed-invoice-result-table';
import styles from './InvoiceParsingResultTable.module.scss';
import clsx from 'clsx';

type TInvoiceParsingResultTableProps = {
  id: string | null;
  isFiltersAllowed: boolean;
};

const InvoiceParsingResultTable = ({
  id,
  isFiltersAllowed,
}: TInvoiceParsingResultTableProps) => {
  const {
    columns,
    data,
    total,
    handleTableChange,
    loading,
    width,
    current,
    setErrorsOnly,
  } = useParsedInvoiceResultList(id);

  return (
    <div className={styles.table}>
      <div
        className={clsx(styles.controls, {
          [styles.end]: !isFiltersAllowed,
        })}
      >
        {isFiltersAllowed && (
          <div className={styles.switch}>
            <Typography.Text>Show invalid data only</Typography.Text>
            <Switch disabled={loading} onChange={setErrorsOnly} />
          </div>
        )}
        <Button
          icon={<IconDownload width="16" height="16" />}
          onClick={() => {}}
        >
          Export to .xlsx
        </Button>
      </div>
      <Table<TMappedParsedInvoiceResultRecord>
        emptyTextDescription={NO_PARSED_RESULTS}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        width={width}
        height={'384px'}
        total={total}
        current={current}
      />
    </div>
  );
};

export default InvoiceParsingResultTable;
