import {
  HTTP,
  TValidationError,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TCreateUserParams,
  TGetCurrentUserResponse,
  TGetUserResponse,
  TGetUsersParams,
  TGetUsersResponse,
  TUpdateUserParams,
  TUpdateUserStatusParams,
} from 'src/types/backend';
import { getParamsWithSearch } from 'src/utils/get-params';

const useUsers = () => {
  const { fetch } = useNetworkContext();

  const getUsers = async (data: TGetUsersParams) => {
    const params = getParamsWithSearch(data);
    const result = await fetch<TGetUsersResponse, unknown>({
      url: '/users/all/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getUser = async (id: string) => {
    const result = await fetch<TGetUserResponse, unknown>({
      url: `/users/${id}`,
      method: HTTP.GET,
    });

    return result;
  };

  const createUser = async (data: TCreateUserParams) => {
    const result = await fetch<unknown, TValidationError>({
      url: '/users/invite',
      method: HTTP.POST,
      data,
    });

    return result;
  };
  const updateUserStatus = async (data: TUpdateUserStatusParams) => {
    const result = await fetch<unknown, TValidationError>({
      url: `/users/${data.id}`,
      method: HTTP.PATCH,
      data: {
        status: data.status,
      },
    });

    return result;
  };

  const inviteUser = async (id: string) => {
    const result = await fetch<unknown, unknown>({
      url: `/users/${id}/resendInvite`,
      method: HTTP.POST,
    });

    return result;
  };

  const updateUser = async (data: TUpdateUserParams) => {
    const result = await fetch<unknown, TValidationError>({
      url: `/users/${data.id}`,
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  const validateEmail = async (email: string) => {
    const result = await fetch<unknown, unknown>({
      url: `/users/${email}`,
      method: HTTP.GET,
    });

    return result;
  };

  const getCurrentUser = async () => {
    const result = await fetch<TGetCurrentUserResponse, unknown>({
      url: '/users/current',
      method: HTTP.GET,
    });

    return result;
  };

  return {
    getUsers,
    createUser,
    validateEmail,
    getUser,
    updateUser,
    inviteUser,
    updateUserStatus,
    getCurrentUser,
  };
};

export default useUsers;
