import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { InviteFormFields } from 'src/constants/invite';
import { useMessageContext } from 'src/contexts/message-context';
import useCompanies from './use-companies';
import usePreview from './use-preview';
import useUsers from './use-users';

type TUseInviteFormParams = {
  isCustomer: boolean;
  setEmailPreview: (value: string) => void;
  onClose: () => void;
};

const useInviteForm = ({
  setEmailPreview,
  isCustomer,
  onClose,
}: TUseInviteFormParams) => {
  const { getEmailPreview } = usePreview();
  const { getCurrentUser } = useUsers();
  const { inviteCompany } = useCompanies();
  const { showSuccessMessage } = useMessageContext();
  const [form] = Form.useForm<Record<InviteFormFields, string>>();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [companyError, setCompanyError] = useState('');

  const company = Form.useWatch(InviteFormFields.COMPANY_NAME, form);
  const email = Form.useWatch(InviteFormFields.EMAIL, form);

  useEffect(() => {
    const fetchUserInfo = async () => {
      setLoading(true);
      const user = await getCurrentUser();
      setLoading(false);
      const userInfo = user.result?.data;
      if (!userInfo) {
        return;
      }
      form.setFieldsValue({
        [InviteFormFields.SUBJECT]: `[HivePix] ${userInfo.fullName} invited you to join HivePix`,
        [InviteFormFields.TITLE]: 'You are invited to join HivePix',
        [InviteFormFields.EMAIL_BODY]: `${userInfo.fullName} from ${userInfo.companyName} has invited you to use HivePix to collaborate with them.
If you have any questions to ${userInfo.fullName}, contact them directly via email ${userInfo.email}.
Welcome aboard!`,
      });
    };
    fetchUserInfo();
  }, []);

  const submit = async () => {
    try {
      setEmailError('');
      setCompanyError('');
      const data = await form.validateFields();
      setLoading(true);
      const result = await inviteCompany(
        {
          companyName: data.companyName,
          emailSubject: data.subject,
          emailTitle: data.title,
          email: data.email,
          emailBody: data.emailBody,
        },
        isCustomer
      );
      setLoading(false);
      if (result.errors) {
        const errors = result.errors.response?.data.errors;
        if (errors) {
          Object.keys(errors).forEach((key) => {
            if (
              key.toLowerCase() === InviteFormFields.COMPANY_NAME.toLowerCase()
            ) {
              setCompanyError(errors[key][0]);
            }
            if (key.toLowerCase() === InviteFormFields.EMAIL.toLowerCase()) {
              setEmailError(errors[key][0]);
            }
          });
        }
        return;
      } else {
        showSuccessMessage('Invitation sent');
      }
      onClose();
    } catch {}
  };
  const fetchPreview = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const preview = await getEmailPreview({
      recipientEmail: values.email,
      subject: values.subject,
      title: values.title,
      body: values.emailBody,
    });
    if (preview.result?.data) {
      setEmailPreview(preview.result.data);
    }
    setLoading(false);
  };

  return {
    form,
    submit,
    loading,
    company,
    email,
    fetchPreview,
    emailError,
    companyError,
    setCompanyError,
    setEmailError
  };
};

export default useInviteForm;
