import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Tag,
  Typography,
  UploadProps,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import Dragger from 'antd/es/upload/Dragger';
import { useMemo } from 'react';
import FormItem from 'src/components/form-item/FormItem';
import { IconChipExtraction } from 'src/components/icons/chip-extraction/IconChipExtraction';
import { IconHelp } from 'src/components/icons/help/IconHelp';
import Select from 'src/components/select/Select';
import Cell from 'src/components/table/cell/Cell';
import File from 'src/components/table/file/File';
import {
  FileStatus,
  fileStatus,
  fileStatusColor,
} from 'src/constants/invoices';
import { URL_REGEX } from 'src/constants/regex';
import { INCORRECT_URL, REQUIRED } from 'src/constants/validation';
import { useUserContext } from 'src/contexts/user-context';
import { IProduct } from 'src/types/general';
import { formatDate } from 'src/utils/date-formatter';
import { formatNumber } from 'src/utils/number-formatter';
import { Mode } from '../ProductsDrawer';
import styles from './ProductDrawerForm.module.scss';

type TProductDrawerFormProps = {
  mode: Mode | null;
  onClose: () => void;
  submit: () => void;
  reupload: () => void;
  draggerProps: UploadProps;
  isChanged: boolean;
  form: FormInstance<IProduct>;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
  fetchEpdLink: () => void;
  deleteEpdFile: () => void;
  fetchingError: string;
} & Omit<IProduct, 'canEdit' | 'canDelete' | 'id' | 'unitOfMeasurementId'>;

const ProductDrawerForm = ({
  mode,
  onClose,
  form,
  name,
  epdFile,
  createdAt,
  createdByName,
  modifiedAt,
  modifiedByName,
  draggerProps,
  isChanged,
  submit,
  reupload,
  currencies,
  uoms,
  externalEpdUrl,
  fetchEpdLink,
  deleteEpdFile,
  fetchingError,
}: TProductDrawerFormProps) => {
  const addMode = mode === Mode.CREATE;

  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const label = mode === Mode.CREATE ? 'Add' : 'Save';

  const fetchDisabled = useMemo(
    () =>
      !externalEpdUrl ||
      !URL_REGEX.test(externalEpdUrl) ||
      epdFile?.hiveStatus === FileStatus.PROCESSING,
    [externalEpdUrl, epdFile]
  );

  const isAUDeleteAvailable =
    isAdvancedUser &&
    (!epdFile?.hiveStatus ||
      epdFile.hiveStatus === FileStatus.FAILED ||
      epdFile.hiveStatus === FileStatus.UPLOADED);

  const isAdminDeleteAvailable =
    isAdmin &&
    (!epdFile?.hiveStatus || epdFile.hiveStatus !== FileStatus.PROCESSING);

  return (
    <Form form={form} layout="vertical" requiredMark={true}>
      <FormItem
        label="Product name"
        name="name"
        isFeedbackShown={!name}
        rules={[{ required: true, message: REQUIRED }, { max: 200 }]}
        hasFeedback
      >
        <Input suffix={!name ? undefined : <span />} />
      </FormItem>
      <FormItem
        label="Material number"
        name="materialNumber"
        rules={[{ max: 50 }]}
        tooltip={{
          title: 'Alt.: Stock code',
          icon: (
            <div className={styles.tooltipContainer}>
              <IconHelp height="16" width="16" className={styles.tooltip} />
            </div>
          ),
          placement: 'right',
        }}
      >
        <Input />
      </FormItem>
      <FormItem
        label="Part number"
        name="partNumber"
        rules={[{ max: 50 }]}
        tooltip={{
          title: 'Alt.: Item number',
          icon: (
            <div className={styles.tooltipContainer}>
              <IconHelp height="16" width="16" className={styles.tooltip} />
            </div>
          ),
          placement: 'right',
        }}
      >
        <Input />
      </FormItem>
      <FormItem label="Barcode" name="barCode" rules={[{ max: 50 }]}>
        <Input />
      </FormItem>
      <div className={styles.row}>
        <FormItem name={'rrpPrice'} label="RRP">
          <InputNumber<number>
            addonAfter={
              <FormItem className={styles.noMargin} name={'currency'}>
                <Select options={currencies} className={styles.currencyAddon} />
              </FormItem>
            }
            min={0.01}
            controls={false}
            formatter={(value) => {
              if (!value && value !== 0) {
                return '';
              }
              if (value <= 0) {
                return formatNumber(0.01);
              }
              return formatNumber(value);
            }}
          />
        </FormItem>
        /
        <FormItem name={'quantity'} label="UoM">
          <InputNumber<number>
            className={styles.quantity}
            addonAfter={
              <FormItem
                name={'unitOfMeasurementId'}
                className={styles.noMargin}
              >
                <Select options={uoms} className={styles.uomAddon} />
              </FormItem>
            }
            min={0.01}
            controls={false}
            formatter={(value) => {
              if (!value && value !== 0) {
                return '';
              }
              if (value <= 0) {
                return formatNumber(0.01);
              }
              return formatNumber(value);
            }}
          />
        </FormItem>
      </div>
      <FormItem
        label="EPD Link"
        name="externalEpdUrl"
        rules={[
          { max: 200 },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              const isValid = URL_REGEX.test(value as string);
              if (isValid) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
            message: INCORRECT_URL,
          },
        ]}
        validateStatus={fetchingError ? 'error' : undefined}
        help={fetchingError || undefined}
      >
        <div className={styles.epdUrl}>
          <Input value={externalEpdUrl || ''} />
          {!addMode && (
            <Button
              disabled={fetchDisabled}
              onClick={fetchEpdLink}
              icon={<IconChipExtraction height="16" width="16" />}
            >
              Fetch file
            </Button>
          )}
        </div>
      </FormItem>
      <FormItem label="EPD file" name="epdFileName">
        {epdFile ? (
          <Cell isValue={!!epdFile}>
            <File
              file={epdFile}
              reupload={reupload}
              readOnly={epdFile.hiveStatus === FileStatus.PROCESSING}
              onDelete={deleteEpdFile}
              isThirdActionVisible={
                isAUDeleteAvailable || isAdminDeleteAvailable
              }
              isSoft={isAdmin && epdFile?.hiveStatus === FileStatus.PROCESSED}
            />
          </Cell>
        ) : !addMode ? (
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single upload only.</p>
          </Dragger>
        ) : (
          <Typography.Text className={styles.epd}>
            File upload will be enabled after you add the product.
          </Typography.Text>
        )}
      </FormItem>
      {!addMode && (
        <>
          {epdFile && (
            <FormItem
              className={styles.label}
              label="EPD Hive status"
              name="epdStatus"
            >
              <Tag color={fileStatusColor[epdFile.hiveStatus]}>
                {fileStatus[epdFile.hiveStatus]}
              </Tag>
            </FormItem>
          )}
          <div className={styles.info}>
            <FormItem
              className={styles.label}
              label="Created on"
              name="createdAt"
            >
              <Cell isValue={!!createdAt}>
                {formatDate({ date: createdAt })}
              </Cell>
            </FormItem>
            <FormItem
              className={styles.label}
              label="Created by"
              name="createdByName"
            >
              <Cell isValue={!!createdByName}>{createdByName}</Cell>
            </FormItem>
            <FormItem
              className={styles.label}
              label="Modified on"
              name="modifiedAt"
            >
              <Cell isValue={!!modifiedAt}>
                {formatDate({ date: modifiedAt })}
              </Cell>
            </FormItem>
            <FormItem
              className={styles.label}
              label="Modified by"
              name="modifiedByName"
            >
              <Cell isValue={!!modifiedByName}>{modifiedByName}</Cell>
            </FormItem>
          </div>
        </>
      )}
      <div className={styles.buttonContainer}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!isChanged && mode === Mode.EDIT}
          onClick={submit}
          htmlType="submit"
          type="primary"
        >
          {label}
        </Button>
      </div>
    </Form>
  );
};

export default ProductDrawerForm;
