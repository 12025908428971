import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import {
  ParsedInvoiceResultColumns,
  defaultParsedInvoiceResultColumns,
  parsedInvoiceResultColumnLabel,
  parsedInvoiceResultColumnRender,
  parsedInvoiceResultColumnSortOrder,
  parsedInvoiceResultColumnWidth,
} from 'src/constants/parsed-invoices-result';
import { IParsedInvoiceResult } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';

export type TMappedParsedInvoiceResultRecord = IParsedInvoiceResult & {
  key: number;
};

export const getMappedParsedInvoiceResultData = (
  data: IParsedInvoiceResult[]
): TMappedParsedInvoiceResultRecord[] => {
  return data.map((row, id) => {
    const res: TMappedParsedInvoiceResultRecord = {
      ...row,
      key: id,
    };
    return res;
  });
};

export const getParsedInvoiceResultColumnSetting = () => {
  const result: ColumnsType<TMappedParsedInvoiceResultRecord> =
    defaultParsedInvoiceResultColumns.map(
      (
        property
      ): ArrayElement<ColumnsType<TMappedParsedInvoiceResultRecord>> => {
        switch (property) {
          case ParsedInvoiceResultColumns.PRODUCT_NAME:
          case ParsedInvoiceResultColumns.MATERIAL_NUMBER:
          case ParsedInvoiceResultColumns.PART_NUMBER:
          case ParsedInvoiceResultColumns.BARCODE:
          case ParsedInvoiceResultColumns.ISSUE:
            return {
              title: parsedInvoiceResultColumnLabel[property],
              width: parsedInvoiceResultColumnWidth[property],
              key: property,
              ellipsis: true,
              defaultSortOrder: parsedInvoiceResultColumnSortOrder[property],
              dataIndex: property,
              sorter: true,
              showSorterTooltip: false,
              sortIcon: (props) => (
                <IconSort
                  width="16"
                  height="16"
                  order={props.sortOrder as SortOrder}
                />
              ),
              render: parsedInvoiceResultColumnRender[property],
            };
          default:
            return { hidden: true };
        }
      }
    );
  return result;
};
