import React from 'react';
import SignInModal from 'src/components/sign-in/modal/SignInModal';
import styles from './SignIn.module.scss';

const SignIn: React.FC = () => {
  return (
    <div className={styles.container}>
      <SignInModal />
    </div>
  );
};

export default SignIn;
