import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { useMessageContext } from 'src/contexts/message-context';
import { useModalContext } from 'src/contexts/modal-context';
import { useNavigationContext } from 'src/contexts/navigation-context';
import { useUserContext } from 'src/contexts/user-context';
import { ITableParams } from 'src/types/generic';
import {
  getAssignedProductsColumnSetting,
  getMappedProductOffersData,
} from 'src/utils/map-assigned-product-table';
import useCompanies from './use-companies';
import useProductOfferTableForm from './use-product-offer-table-form';
import useTableWidth from './use-table-width';

const useCustomerDetails = () => {
  const { customer_id } = useParams();

  const customerId = customer_id || '';

  const {
    getCustomer,
    getCustomerProducts,
    editAssignedProduct,
    exportProductOffers,
  } = useCompanies();

  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const basicUser = !isAdmin && !isAdvancedUser;

  const { showErrorMessage, showSuccessMessage } = useMessageContext();

  const { modalContextDispatch } = useModalContext();
  const { setTitle } = useNavigationContext();
  const { getAllMeasurements, loading: measurementsLoading } =
    useMeasurementsContext();

  const {
    form,
    data,
    setEditRow,
    unsetEditRow,
    isEditing,
    uoms,
    currencies,
    setData,
  } = useProductOfferTableForm();

  const [customerName, setCustomerName] = useState<string | null>('');
  const [customerStatus, setCustomerStatus] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'createdAt',
    sortOrder: SortOrder.DESC,
  });

  const columns = useMemo(() => {
    const res = getAssignedProductsColumnSetting({
      modalDispatch: modalContextDispatch,
      setEditRow,
      setLoading,
      unsetEditRow,
      edit: editAssignedProduct,
      customerId,
      uoms,
      currencies,
      actionsHidden: customerStatus || basicUser,
      isEditing,
      form,
      showSuccessMessage,
      showErrorMessage,
    });
    return res;
  }, [tableParams, uoms, currencies, customerStatus, isEditing]);

  const fetchProducts = async () => {
    setLoading(true);
    const productsResult = await getCustomerProducts(customerId, tableParams);
    const productsResponse = productsResult.result?.data;

    if (productsResponse) {
      setData(getMappedProductOffersData(productsResponse.data));
      setTotal(productsResponse.totalCount);
    }
    const customerResult = await getCustomer(customerId);
    const customerResponse = customerResult.result?.data;

    if (customerResponse) {
      setCustomerName(customerResponse.name);
      setTitle([customerResponse.name]);
      setCustomerStatus(customerResponse.isDeleted);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllMeasurements();
  }, []);

  useEffect(() => {
    fetchProducts();
    const broadcast = new BroadcastChannel(BROADCAST_NAME);
    broadcast.onmessage = fetchProducts;
    return () => {
      broadcast.close();
    };
  }, [tableParams]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const tableWidth = useTableWidth(columns);

  const exportProducts = async () => {
    await exportProductOffers(
      {
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: total,
        },
      },
      customerId,
      false
    );
  };

  return {
    handleTableChange,
    loading: loading || measurementsLoading,
    columns,
    width: tableWidth,
    data,
    total,
    customerName,
    form,
    open,
    customerId,
    setOpen,
    customerStatus,
    exportProducts,
  };
};

export default useCustomerDetails;
