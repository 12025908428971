import ModalWrapper from '../../modal/wrapper/ModalWrapper';
import AssignForm from '../form/AssignForm';

type TAssignProductsModalProps = {
  onClose: () => void;
  visible: boolean;
  productId: string;
};

const AssignCustomersModal = ({
  onClose,
  visible,
  productId,
}: TAssignProductsModalProps) => {
  return (
    <ModalWrapper
      visible={visible}
      width={1128}
      maxHeight={498}
      title="Assign customers to product"
      close={onClose}
    >
      <AssignForm productId={productId} close={onClose} />
    </ModalWrapper>
  );
};

export default AssignCustomersModal;
